import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../utils/auth/AuthService.tsx';
import { AllRoles } from '../utils/constants/AllRoles';
import InformantDialog from './InformantDialog.jsx';
import CaseViewDateTimeField, { ActionList } from './CaseView/CaseViewDateTimeField.jsx';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DecedentNameHUD from '../utils/components/DecedentNameHUD';

const IdentificationDialog = ({
  isIdentificationDialogOpen,
  setIsIdentificationDialogOpen,
  selectedIdentification = [],
  setSelectedIdentification = () => [],
  handleIdentificationSubmit,
}) => {
  const formik = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [modalityOptions, setModalityOptions] = useState([]);
  const [placeOfIdOptions, setPlaceOfIdOptions] = useState([]);
  const [idOutcomeOptions, setIdOutcomeOptions] = useState([]);
  const [pendingChangeEvent, setPendingChangeEvent] = useState(null);
  const [isInformantDialogOpen, setIsInformantDialogOpen] = useState(false);
  const [selectedInformant, setSelectedInformant] = useState([]);
  const [meOptions, setMEOptions] = useState([]);
  const [identifiedByOptions, setIdentifiedByOptions] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();
  const hasAccessToApproveID =
    user?.roleCheck([AllRoles.Medical_Examiner, AllRoles.Systems_Admin, AllRoles.Confirm_Id]) ??
    false;

  const dismissAlert = () => {
    setShowErrorAlert(false);
  };

  const handleIdentificationDialogClose = () => {
    setIsIdentificationDialogOpen(false);
    setShowErrorAlert(false);
    setValidationErrors([]);
  };

  const handleIdentificationInputChange = (newValue, fieldName) => {
    setSelectedIdentification(prevIdentification => ({
      ...prevIdentification,
      [fieldName]: newValue,
      reportedBy: prevIdentification?.reportedBy?.optionSeq ? prevIdentification?.reportedBy : {
        optionSeq: user?.userSeq,
        optionName: user?.userFirstName
          ? `${user?.userLastName}, ${user?.userFirstName}`
          : user?.userLastName,
      },
      ...(fieldName === 'isAccept' && {
        meConfirmationUser: {
          optionSeq: user?.userSeq,
          optionName: user?.userFirstName
            ? `${user?.userLastName}, ${user?.userFirstName}`
            : user?.userLastName,
        },
      }),
      isChgInd: 1,
    }));
  };

  const handleNewContactClick = event => {
    setPendingChangeEvent({ event });
    setSelectedInformant([]);
    setIsInformantDialogOpen(true);
  };

  const handleNewContactSubmit = option => {
    const newContactOption = {
      optionSeq: option.informantSeq,
      optionName: `${option.personLastName || ''}${
        option.personLastName ? (option.personFirstName ? ', ' : '') : ''
      }${option.personFirstName || ''}`,
    };
    setIsInformantDialogOpen(false);
    if (pendingChangeEvent) {
      handleIdentificationInputChange(newContactOption, 'identifiedBy');
      setPendingChangeEvent(null);
    }
  };

  const handleSubmit = async () => {
    const errors = [];
    if (!selectedIdentification?.identifiedBy) {
      errors.identifiedBy = 'Identified By is required';
    }
    if (!selectedIdentification?.modality) {
      errors.modality = 'Modality is required';
    }

    setValidationErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    setShowErrorAlert(hasErrors);

    if (hasErrors) {
      return;
    }

    const identificationToSubmit = selectedIdentification.identificationDetailSeq
      ? selectedIdentification
      : {
          ...selectedIdentification,
          identificationDetailSeq: uuidv4(),
          isActive: 1,
          isNewInd: 1,
        };

    handleIdentificationSubmit(identificationToSubmit);
    setIsIdentificationDialogOpen(false);
  };

  const fetchModalityOptions = async () => {
    fetch(REACT_APP_API_URL + 'getmethodoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setModalityOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchPlaceOfIdentificationOptions = async () => {
    fetch(REACT_APP_API_URL + 'getplaceofidoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setPlaceOfIdOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchIdentificationOutcomeOptions = async () => {
    fetch(REACT_APP_API_URL + 'getidoutcomeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setIdOutcomeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchMEs = async () => {
    const queryParams = new URLSearchParams();
    if (formik.values?.caseSummary?.jdxSeq) {
      queryParams.append('jdxList', formik.values?.caseSummary?.jdxSeq);
    }

    await fetch(`${REACT_APP_API_URL}get-pathologist-options?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setMEOptions(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchModalityOptions();
    fetchPlaceOfIdentificationOptions();
    fetchIdentificationOutcomeOptions();
    // fetchMEs();
  }, []);

  useEffect(() => {
    //Identified By options
    const filteredInformantList =
      formik.values?.caseDemographics?.informantList?.filter(
        informant => informant.isActive === 1
      ) ?? [];

    const newIdentifiedByOptions = [
      { optionSeq: 'new', optionName: 'Add New Contact' },
      ...filteredInformantList?.map(option => ({
        optionSeq: option.informantSeq,
        optionName: `${option.personLastName || ''}${
          option.personLastName ? (option.personFirstName ? ', ' : '') : ''
        }${option.personFirstName || ''}`,
        relship: option?.relship?.optionName ?? '',
      })),
    ];
    setIdentifiedByOptions(newIdentifiedByOptions);
  }, [formik.values?.caseDemographics?.informantList]);

  const [isNotesFocused, setIsNotesFocused] = useState(false);

  const handleNotesFocus = () => {
    setIsNotesFocused(true);
  };

  const handleNotesBlur = () => {
    setIsNotesFocused(false);
  };

  return (
    <>
      <Dialog
        open={isIdentificationDialogOpen}
        onClose={handleIdentificationDialogClose}
        PaperProps={{
          style: {
            minWidth: isMobile ? '95%' : isLaptop ? '70%' : '50%',
            margin: '0 auto',
          },
        }}
      >
        <DialogTitle>
          <span>Add/Edit Identification</span>
          {showErrorAlert && (
            <div style={{ paddingTop: '1rem' }}>
              <Alert severity='error' isOpen={showErrorAlert} onClose={dismissAlert}>
                {Object.keys(validationErrors)?.map(fieldName => (
                  <div style={{ fontSize: '14px' }}>
                    <li key={fieldName}> {validationErrors[fieldName]}</li>
                  </div>
                ))}
              </Alert>
            </div>
          )}
        </DialogTitle>

        <DialogContent>
          <DecedentNameHUD />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id='caseDemographics.identifiedBy'
                size='small'
                options={identifiedByOptions || []}
                value={selectedIdentification?.identifiedBy || null}
                onChange={(event, newValue) => {
                  if (newValue?.optionSeq === 'new') {
                    handleNewContactClick(event);
                  } else {
                    delete formik.errors.identifiedBy;
                    handleIdentificationInputChange(newValue, 'identifiedBy');
                  }
                }}
                getOptionLabel={option => option.optionName}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={
                      option.optionSeq === 'new'
                        ? {
                            fontStyle: 'italic',
                            color: '#5C72FF',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        : {}
                    }
                  >
                    {`${option.optionName|| ''}${option.relship ? ` (${option.relship})` : ''}`}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <div>
                        {'Identified By '}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  />
                )}
                style={{ marginTop: '1rem' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id='caseDemographics.modality'
                size='small'
                options={modalityOptions}
                value={selectedIdentification?.modality || null}
                onChange={(event, newValue) => {
                  delete formik.errors.modality;
                  handleIdentificationInputChange(newValue, 'modality');
                }}
                getOptionLabel={option => option.optionName}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <div>
                        {'Modality '}
                        <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                  />
                )}
                style={{ marginTop: '1rem' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='caseDemographics.source'
                label='Notes'
                type='text'
                size='small'
                value={selectedIdentification?.source || ''}
                onChange={e => handleIdentificationInputChange(e.target.value, 'source')}
                error={formik.touched.source && Boolean(formik.errors.source)}
                helperText={formik.touched.source && formik.errors.source?.toString()}
                fullWidth
                helperText={
                  formik.touched.source && formik.errors.source
                    ? formik.errors.source.toString()
                    : isNotesFocused
                    ? `${selectedIdentification?.source?.length || 0}/50`
                    : ''
                }
                fullWidth
                inputProps={{
                  maxLength: 50,
                }}
                onFocus={handleNotesFocus}
                onBlur={handleNotesBlur}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                id='caseDemographics.placeOfIdentification'
                size='small'
                options={placeOfIdOptions}
                value={selectedIdentification?.placeOfIdentification || null}
                onChange={(event, newValue) => {
                  handleIdentificationInputChange(newValue, 'placeOfIdentification');
                }}
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Place Of Identification' />}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                  id='caseDemographics.identificationDateTime'
                  name='caseDemographics.identificationDateTime'
                  label='Date/Time Of Identification'
                  fullWidth
                  size='small'
                  value={dayjs(selectedIdentification?.identificationDateTime)}
                  onChange={date => {
                    handleIdentificationInputChange(
                      dayjs(date).format('YYYY-MM-DDTHH:mm:ss'),
                      'identificationDateTime'
                    );
                  }}
                  error={
                    formik.touched.identificationDateTime &&
                    Boolean(formik.errors.identificationDateTime)
                  }
                  helperText={
                    formik.touched.identificationDateTime &&
                    formik.errors.identificationDateTime?.toString()
                  }
                  ampm={false}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  slots={{
                    actionBar: ActionList,
                  }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                id='caseDemographics.identificationOutcome'
                size='small'
                options={idOutcomeOptions}
                value={selectedIdentification?.identificationOutcome || null}
                onChange={(event, newValue) => {
                  handleIdentificationInputChange(newValue, 'identificationOutcome');
                }}
                getOptionLabel={option => option.optionName}
                renderInput={params => <TextField {...params} label='Outcome' />}
              />
            </Grid>
            {hasAccessToApproveID && (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>
                      <strong>ME Confirmation: </strong>
                    </FormLabel>
                    <RadioGroup
                      name='caseDemographics.meconfirmation'
                      value={
                        selectedIdentification.isAccept === true
                          ? 'accept'
                          : selectedIdentification.isReject === true
                          ? 'reject'
                          : ''
                      }
                      onChange={e => {
                        if (e.target.value === 'reject') {
                          handleIdentificationInputChange(true, 'isReject');
                          handleIdentificationInputChange(false, 'isAccept');
                        } else {
                          handleIdentificationInputChange(false, 'isReject');
                          handleIdentificationInputChange(true, 'isAccept');
                        }
                      }}
                      row
                    >
                      <FormControlLabel value='accept' control={<Radio />} label='Accept' />
                      <FormControlLabel value='reject' control={<Radio />} label='Unconfirm' />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <DialogActions style={{ justifyContent: 'left', marginTop: '1rem' }}>
            <Button type='submit' variant='contained' onClick={handleSubmit}>
              Submit
            </Button>
            <Button color='error' onClick={handleIdentificationDialogClose}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <InformantDialog
        isInformantDialogOpen={isInformantDialogOpen}
        setIsInformantDialogOpen={setIsInformantDialogOpen}
        selectedInformant={selectedInformant}
        setSelectedInformant={setSelectedInformant}
        handleNewContactCallback={handleNewContactSubmit}
      />
    </>
  );
};

export default IdentificationDialog;
