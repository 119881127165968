import { ChevronLeft } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { format, formatDistanceToNow } from 'date-fns';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { Conversation, ConversationTypes } from './conversation.types';
import { useConversation } from './ConversationsContext';

const { REACT_APP_API_URL } = process.env;

export const ConversationHeader = ({
  onBackClick,
  isMobile,
  isDarkMode = false,
  onConversationUpdated = (conversation: Conversation) => {},
  supportAgentName = 'Support',
}: {
  onBackClick: () => void;
  isMobile: boolean;
  isDarkMode?: boolean;
  onConversationUpdated?: (conversation: Conversation) => void;
  supportAgentName?: string;
}) => {
  const { selectedConversation, updateConversationOpenStatus, refreshConversations, refreshing } =
    useConversation();
  const creator = selectedConversation?.participants.find(
    p => p.userSeq.toLowerCase() === selectedConversation.createdByUserSeq.toLowerCase()
  );

  const { user } = useAuth();
  const isSystemAdmin = user.roleCheck(['SYSTEMS-ADMIN', '8f7']);

  const hideParticipantList =
    !isSystemAdmin &&
    [
      ConversationTypes.Feedback,
      ConversationTypes.Error_Report,
      ConversationTypes.New_Account_Request,
    ].includes(selectedConversation.conversationType.conversationTypeSeq);

  const handleToggleStatus = async () => {
    if (!selectedConversation) return;

    updateConversationOpenStatus({
      conversationSeq: selectedConversation.conversationSeq,
      isOpen: !selectedConversation.isOpen,
    });
  };

  return (
    <Box
      sx={{
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        flexShrink: 0,
        position: 'sticky',
        top: -1,
        backdropFilter: 'blur(32px)',
        backgroundColor: user.isDarkMode ? 'rgba(18, 18, 18, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        color: isDarkMode ? '#fff' : 'inherit',
        zIndex: 10,
      }}
    >
      <Stack direction='row' spacing={2}>
        {isMobile && (
          <IconButton onClick={onBackClick}>
            <ChevronLeft />
          </IconButton>
        )}
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 0,
              gap: 2,
            }}
          >
            <Typography variant='h6' sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {selectedConversation?.topic}
            </Typography>

            <Stack direction='row' spacing={1} sx={{ flexShrink: 0 }}>
              {isSystemAdmin && (
                <Chip
                  label={selectedConversation?.conversationType.typeName}
                  size='small'
                  variant='outlined'
                />
              )}
              <Chip
                label={selectedConversation?.isOpen ? 'Open' : 'Closed'}
                color={selectedConversation?.isOpen ? 'success' : 'default'}
                onClick={isSystemAdmin ? handleToggleStatus : undefined}
                size='small'
                sx={{ cursor: 'default' }}
              />
            </Stack>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant='body2' color={isDarkMode ? 'text.secondary' : 'text.secondary'}>
              Started by {creator.userName} on {formatDate(selectedConversation?.createdAt || '')}
            </Typography>

            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
              <>
                {hideParticipantList ? (
                  <>
                    <Chip
                      key='convo-creator'
                      label={`${creator.personFirstName} ${creator.personLastName} (${creator.userName})`}
                      size='small'
                      variant='outlined'
                    />

                    <Chip
                      key='support-agent-name'
                      label={supportAgentName}
                      size='small'
                      variant='outlined'
                    />
                  </>
                ) : (
                  selectedConversation?.participants.map(participant => (
                    <Chip
                      key={participant.userSeq}
                      label={`${participant.personFirstName} ${participant.personLastName} (${participant.userName})`}
                      size='small'
                      variant='outlined'
                    />
                  ))
                )}
              </>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
/**
 * Converts a date string to a Date object, handling various formats
 * and ensuring timezone-agnostic behavior
 */
export const parseDate = (dateString: string): Date => {
  if (!dateString) {
    throw new Error('Invalid date string: empty or undefined');
  }

  // Remove any timezone information to treat the date as local
  const cleanDateString = dateString.replace(/([+-]\d{2}:?\d{2}|Z)$/, '');

  // Parse the date in local timezone
  const date = new Date(cleanDateString);

  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date string: ${dateString}`);
  }

  return date;
};

/**
 * Formats a date string to a readable format in local timezone
 * Example output: "Mar 15, 2024 2:30 PM"
 */
export const formatDate = (dateString: string): string => {
  try {
    const date = parseDate(dateString);
    return format(date, 'MMM d, yyyy h:mm a');
  } catch (error) {
    console.error('Date formatting error:', error, 'for date string:', dateString);
    return 'Invalid date';
  }
};

/**
 * Formats how long ago a date was in a human-readable format
 * Example output: "2 hours ago", "3 days ago"
 */
export const formatDistanceWithFallback = (dateString: string): string => {
  try {
    const date = parseDate(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    console.error('Date distance formatting error:', error, 'for date string:', dateString);
    return 'Unknown time ago';
  }
};

/**
 * Formats a date for API requests, ensuring consistent timezone handling
 * Example output: "2024-03-15T14:30:00"
 */
export const formatDateForApi = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};
