import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import React from 'react';
import '../../../App.css';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = gridPageSelector(apiRef);
  const pageCount = gridPageCountSelector(apiRef);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => (
        <PaginationItem {...props2} sx={{ fontFamily: 'DataGrid, sans-serif' }} disableRipple />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ mr: 3 }}
    />
  );
}

export const ReportDataGridToolbar = ({ title, casecount, count, expanded, setExpanded }) => {
  const theme = useTheme();

  const toggleExpandDataGrid = event => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const getTitleAndCount = () => {
    var titleAndCount = '';
    if (title != null && casecount === true) titleAndCount = title + ': ' + count;
    else if (title != null && casecount != null) titleAndCount = title + ': ' + casecount;
    else if (title != null && casecount == null) titleAndCount = title;
    else if (title == null && casecount === true) titleAndCount += 'Cases Found: ' + count;
    else if (title == null && casecount != null) titleAndCount += 'Cases Found: ' + casecount;
    return titleAndCount;
  };

  return (
    <GridToolbarContainer>
      <Stack sx={{ width: '100%' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ p: 2 }}>
          <Typography variant='h5'>{getTitleAndCount()}</Typography>
          <Stack direction='row' spacing={2}>
            <GridToolbarExport />
            <IconButton
              sx={{
                color: theme.palette.text.primary,
                opacity: 0.8,
              }}
              size='small'
              onClick={toggleExpandDataGrid}
            >
              <OpenInFullIcon fontSize='small' />
            </IconButton>
          </Stack>
        </Stack>
        <Divider sx={{ opacity: 0.6 }} />
      </Stack>
    </GridToolbarContainer>
  );
};

export default function CondensedReportDataGrid({
  loading,
  rows,
  columns,
  chatcontent = null,
  title,
  idcolumn,
  casecount = null,
  rowsperpage = 5,
  expanded,
  setExpanded,
  ...props
}) {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsperpage,
    page: 0,
  });

  return (
    <Stack spacing={2}>
      {chatcontent != null && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <Skeleton
              variant='rounded'
              animation='wave'
              height={50}
              sx={{
                borderRadius: 20,
                width: '80%',
              }}
            />
          ) : (
            chatcontent
          )}
        </Box>
      )}
      <DataGridPremium
        {...props}
        columns={columns}
        rows={rows}
        loading={loading}
        getRowId={row => row[idcolumn]}
        pagination
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              ID: false,
              resultseq: false,
              caseseq: false,
            },
          },
        }}
        slots={{
          pagination: CustomPagination,
          toolbar: ReportDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title: title,
            casecount: casecount,
            count: rows.length,
            expanded: expanded,
            setExpanded: setExpanded,
          },
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: '0.775rem', // Adjust the font size as needed
            borderRight: '1px solid rgba(200, 200, 200, 0.6)', // Add right border to cells
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid rgba(200, 200, 200, 0.6)', // Add bottom border to rows
          },
        }}
      />
    </Stack>
  );
}
