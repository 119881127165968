import { Typography } from '@mui/material';
import CustomHeader from '../../utils/components/CustomHeader';
import ReportDataGrid from '../../utils/components/ReportDataGrid/ReportDataGrid';
import { useState } from 'react';

export default function AccountRequestView({ handleMenuClick }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <CustomHeader
        title='Account Request View'
        description='View and manage account requests'
        handleMenuClick={handleMenuClick}
      />

      <ReportDataGrid
        title='Account Requests'
        listresults={[]}
        columnsInput={[]}
        loading={loading}
        idcolumn=''
      />
    </>
  );
}
