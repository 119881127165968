import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik';
import 'leaflet/dist/leaflet.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useMap } from 'react-leaflet/hooks';
import * as yup from 'yup';
import { useAuth } from '../../utils/auth/AuthService';
import { useDebouncedValidate } from '../../utils/functions/debouncedFormikValidation';
import { jurisdictionCheck } from '../../utils/functions/jurisdictionCheck';
import { roleCharacteristicsCheck } from '../../utils/functions/roleCharacteristicsCheck';
import __QuickCreatingCaseView from './__QuickCreatingCaseView';
import './map.css';
import SimilarCaseDialog from '../../components/CaseIntake/SimilarCaseDialog';
import { LoadingButton } from '@mui/lab';

// API URI ENVIRONMENT VARIABLE
const { REACT_APP_API_URL } = process.env;

// FORM GETTERS
async function getState(stateName) {
  var stateOptions = null;

  await fetch(REACT_APP_API_URL + 'getstateoptions', {
    method: 'GET',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      stateOptions = data;
    });

  for (var state of stateOptions) {
    if ((await state?.optionName?.toUpperCase()) === stateName?.toUpperCase()) {
      return state;
    }
  }

  return null;
}

async function getCountry(countryName) {
  if (countryName == 'USA') {
    countryName = 'United States';
  }

  var countryOptions = null;

  await fetch(REACT_APP_API_URL + 'getcountryoptions', {
    method: 'GET',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      countryOptions = data;
    });

  for (var country of countryOptions) {
    if (country?.optionName?.toUpperCase() === countryName?.toUpperCase()) {
      return country;
    }
  }

  return null;
}

function getJurisdiction(jdxName, authorizedJdxs) {
  var result = authorizedJdxs?.filter(obj => {
    return obj?.jdxName === jdxName;
  });

  return result[0];
}

function getJurisdictionOptionLabel(option) {
  {
    return option?.jdxName;
  }
}

const getAgencyOptions = async (agencyType, jurisdiction) => {
  if (agencyType == null) {
    return [];
  }

  switch (agencyType?.optionSeq) {
    case '348bd197-50a3-4daf-82d5-862c00e51bcb'.toUpperCase():
      var hcfoptions = [];
      // GET HCF OPTIONS
      await fetch(REACT_APP_API_URL + 'gethcfoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          hcfoptions = data;
        });
      return hcfoptions;

    case '22cd6ec5-93c6-4344-a859-e3daf99797bd'.toUpperCase():
      var leoptions = [];
      // GET LAW ENFORCEMENT OPTIONS
      await fetch(REACT_APP_API_URL + 'getlawenforcementoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          leoptions = data;
        });
      return leoptions;

    case '9da66840-2a60-4e1c-87bc-ee0d02ae199c'.toUpperCase():
      var nhoptions = [];
      //GET NURSING HOME OPTIONS
      await fetch(REACT_APP_API_URL + 'getnursinghomeoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          nhoptions = data;
        });
      return nhoptions;

    case '6172b365-983d-4b30-97b0-fb5221a314e0'.toUpperCase():
      var fhoptions = [];
      // GET FUNERAL HOME OPTIONS
      await fetch(REACT_APP_API_URL + 'getfuneralhomeoptions', {
        method: 'GET',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          fhoptions = data;
        });
      return fhoptions;

    default:
      return [];
  }
};

async function getBLAddressParams(bodyLocationAddress) {
  if (bodyLocationAddress) {
    try {
      const getPlaceParams = {
        IndexName: 'MedexLabPlaceIndex',
        PlaceId: bodyLocationAddress?.PlaceId,
      };

      const results = await new Promise((resolve, reject) => {
        location.getPlace(getPlaceParams, (err, results) => {
          if (err) reject(err);
          else resolve(results);
        });
      });

      const placeData = results?.Place;

      const state = await getState(placeData?.Region);
      const country = await getCountry(placeData?.Country);

      const blAddressParams = {
        AddressLine1:
          (placeData?.AddressNumber ?? '') +
          (placeData?.AddressNumber != null ? ' ' : '') +
          placeData?.Street,
        ApartmentNo: placeData?.UnitType == 'Apt' ? placeData?.UnitNumber : null,
        City: placeData?.Municipality,
        StateSeq: state?.optionSeq,
        Zip: placeData?.PostalCode,
        CountrySeq: country?.optionSeq,
      };

      return blAddressParams;
    } catch (error) {
      console.error(error);
    }
  }
}

// AWS LOCATION SERVICES CONFIGURATION
const AWS = require('aws-sdk');

AWS.config.region = 'us-east-1';

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39',
});

await AWS.config.credentials.get();

const locationOptions = {
  region: AWS.config.region,
  credentials: AWS.config.credentials,
};

// AWS LOCATION SERVICES OBJECT
const location = new AWS.Location(locationOptions);

// SEARCH BAR COMPONENT FOR BL MAP
function MapSearchBar({
  blAddressInput,
  setBlAddressInput,
  blAddressOptions,
  setBlAddressOptions,
  jurisdictionOptions,
}) {
  const map = useMap();
  const formik = useFormikContext();

  const handleAddressInputChange = async (event, data) => {
    // setBodyLocationAddressInput(data);
    setBlAddressInput(data);

    var searchForSuggestionsParams = {
      IndexName: 'MedexLabPlaceIndex',
      Text: data,
      FilterCategories: ['AddressType'],
      BiasPosition: [-74.450507, 40.181042],
    };

    if (data?.length > 0) {
      await location.searchPlaceIndexForSuggestions(
        searchForSuggestionsParams,
        function (err, data) {
          // setBodyLocationAddressOptions(data?.Results);
          setBlAddressOptions(data?.Results);
        }
      );
    } else {
      formik?.setFieldValue('bodyLocationAddress', null);
    }
  };

  const handleAddressTextChange = async (event, data) => {
    formik?.setFieldValue('bodyLocationAddress', data);

    if (data != null && data?.Text?.length > 0) {
      formik?.setFieldValue('bodyLocationAddress', data);

      // Update Map Coordinates
      var getPlaceParams = {
        IndexName: 'MedexLabPlaceIndex',
        PlaceId: data?.PlaceId,
      };

      await location.getPlace(getPlaceParams, function (err, results) {
        var placeData = results?.Place;

        const position = [placeData?.Geometry?.Point[1], placeData?.Geometry?.Point[0]];
        map.flyTo(position, 18);

        if (placeData?.SubRegion != null) {
          if (
            formik?.values?.jurisdiction == null ||
            formik?.values?.jurisdiction?.jdxSeq?.toUpperCase() !==
              getJurisdiction(
                placeData?.SubRegion?.substring(0, placeData?.SubRegion?.length - 7),
                jurisdictionOptions
              )?.jdxSeq?.toUpperCase()
          ) {
            formik?.setFieldValue('agency', null);
          }

          // This automatically sets a jurisdiction when an address is chosen
          // handleJurisdictionChange(
          //   null,
          //   getJurisdiction(
          //     placeData.SubRegion.substring(0, placeData.SubRegion.length - 7),
          //     jurisdictionOptions
          //   )
          // );
        }
      });
    } else {
      formik?.setFieldValue('bodyLocationAddress', null);
    }
  };

  return (
    <Autocomplete
      id='bodyLocationAddress'
      name='bodyLocationAddress'
      options={blAddressOptions}
      value={formik?.values?.bodyLocationAddress}
      isOptionEqualToValue={(option, value) => {
        if (!value) return false;
        return option?.PlaceId?.toUpperCase() === value?.PlaceId?.toUpperCase();
      }}
      size='small'
      getOptionLabel={option => (option?.Text != undefined ? option?.Text : option?.Label)}
      onChange={(event, data) => handleAddressTextChange(event, data)}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Grid container alignItems='center'>
            <Grid item>
              <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
            </Grid>
            <Grid item xs>
              <span
                style={{
                  fontWeight:
                    // option.Text.substring(0, bodyLocationAddressInput.length) ==
                    // bodyLocationAddressInput
                    //   ? 700
                    //   : 400,
                    option?.Text?.substring(0, blAddressInput?.length) == blAddressInput
                      ? 700
                      : 400,
                }}
              >
                {/* {option.Text.substring(0, bodyLocationAddressInput.length) ==
                  bodyLocationAddressInput
                    ? option.Text.substring(0, bodyLocationAddressInput.length)
                    : option.Text.split(',')[0]} */}

                {option?.Text?.substring(0, blAddressInput?.length) == blAddressInput
                  ? option?.Text?.substring(0, blAddressInput?.length)
                  : option?.Text?.split(',')[0]}
              </span>

              <Typography variant='body2' color='text.secondary'>
                {option?.Text}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
      popupIcon={null}
      onInputChange={(event, data) => handleAddressInputChange(event, data)}
      renderInput={params => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return (
          <Paper
            sx={{
              position: 'relative',
              p: '2px 4px',
              ml: 2,
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              maxWidth: 450,
              width: '90%',
              zIndex: 1000,
            }}
          >
            <InputBase
              {...params.InputProps}
              {...params.InputLabelProps}
              {...rest}
              slotProps={{ input: { style: { padding: 0 } } }}
              sx={{
                ml: 1,
                flex: 1,
                textOverflow: 'ellipsis',
                padding: 0,
              }}
              placeholder='Discovery/Scene Location'
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.bodyLocationAddress && Boolean(formik?.errors?.bodyLocationAddress)
              }
              helperText={
                formik?.touched?.bodyLocationAddress && formik?.errors?.bodyLocationAddress
              }
            />
            <IconButton type='button' sx={{ p: '10px' }}>
              <SearchIcon />
            </IconButton>
          </Paper>
        );
      }}
      filterOptions={x => x}
    />
  );
}

export default function QuickCaseIntakeView() {
  // FORM OPTIONS
  const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
  const [agencyTypeOptions, setAgencyTypeOptions] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [callerRelationshipOptions, setCallerRelationshipOptions] = useState([]);
  const [investigationReasonOptions, setInvestigationReasonOptions] = useState([]);

  // SUBMITTING STATE
  const [submitting, setSubmitting] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [newCaseId, setNewCaseId] = useState(null);

  // BODY LOCATION ADDRESS STATE
  const [bodyLocationAddressInput, setBodyLocationAddressInput] = useState(null);
  const [bodyLocationAddressOptions, setBodyLocationAddressOptions] = useState([]);
  const [locationTypeOptions, setLocationTypeOptions] = useState([]);

  // SIMILAR NAMES FOUND STATE
  const [similarCasesDialogOpen, setSimilarCasesDialogOpen] = useState(false);
  const [similarCasesData, setSimilarCasesData] = useState([]);
  const [submitAnyway, setSubmitAnyway] = useState(false);
  const [preSubmitLoading, setPreSubmitLoading] = useState(false);

  // MAP STATE
  const initialCenter = [40.07, -74.5583];
  const initialZoom = 6.5;
  const mapRef = useRef();

  // PAGE UTILITIES
  const auth = useAuth();
  const { user } = useAuth();
  const theme = useTheme();

  const historicDataEntryUser = user?.roleCheck(['18c92818-6969-466b-a82b-c4817fdfacf4']);

  // STATE MANAGEMENT AND FORM VALIDATION
  const validationSchema = yup.object({
    jurisdiction: yup.object().nullable(false).required('Jurisdiction is required'),
    preferredCaseId: yup.string('Enter a case ID').when([], {
      is: () =>
        roleCharacteristicsCheck(auth?.user?.specialRoleCharacteristics, 'DataEntryOperator'),
      then: () =>
        yup
          .string('Enter a case ID')
          .nullable(false)
          .matches(
            '^((0[1-9])|(1[0-9])|(2[0-2]))-[4-9]\\d-\\b(?!0000)\\d{4}\\b$',
            'Please enter a valid case ID before the year 2000'
          )
          .test('check-existing-case-id', 'Case ID already exists', async function (value) {
            if (value) {
              const response = await fetch(REACT_APP_API_URL + `checkifcaseexists?caseId=${value}`);
              const data = await response.json();

              if (data > 0) {
                return false; // Case ID already exists
              }
            }
            return true; // If value is empty or no entry exists, it's valid
          })
          .test(
            'check-jurisdiction-access',
            'You do not have access to this jurisdiction',
            function (value) {
              return jurisdictionCheck(auth?.user?.jdxAccessList, value?.substring(0, 2));
            }
          )
          .required('Case ID is required for legacy cases'),
      otherwise: () => yup.string().nullable(true),
    }),
    suspectedDrugToxicity: yup.boolean(),
    suspectedOpioidDeath: yup.boolean(),
    infectiousDisease: yup.boolean(),
    // bodyLocationAddress: yup
    //   .object()
    //   .when(['suspectedDrugToxicity', 'suspectedOpioidDeath', 'infectiousDisease'], {
    //     is: (valueA, valueB, valueC) => valueA || valueB || valueC,
    //     then: () =>
    //       yup
    //         .object()
    //         .nullable(false)
    //         .required('Discovery/scene is required for real-time monitoring cases'),
    //     otherwise: () => yup.object().nullable(true),
    //   }),
    // locationType: yup
    //   .object()
    //   .when(['suspectedDrugToxicity', 'suspectedOpioidDeath', 'infectiousDisease'], {
    //     is: (valueA, valueB, valueC) => valueA || valueB || valueC,
    //     then: () =>
    //       yup
    //         .object()
    //         .nullable(false)
    //         .required('Location type is required for real-time monitoring cases'),
    //     otherwise: () => yup.object().nullable(true),
    //   }),
    firstName: yup.string("Enter the decedent's first name").nullable(true),
    middleName: yup.string("Enter the decedent's middle name").nullable(true),
    lastName: yup
      .string("Enter the decedent's last name")
      .nullable(false)
      .required('Last name is required'),
    callerLastName: yup
      .string("Enter the caller's last name")
      .nullable(false)
      .required('Caller last name is required'),
    callerFirstName: yup
      .string("Enter the caller's first name")
      .nullable(false)
      .required('Caller first name is required'),
    callerNumber: yup
      .string("Enter the caller's phone number")
      .nullable(false)
      .matches(
        '^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
        'Please enter a valid phone number'
      )
      .required('Caller number is required'),
    agencyType: yup.object().nullable(false).required('Agency type is required'),
  });

  const formik = useFormik({
    initialValues: {
      jurisdiction: null,
      preferredCaseId: null,
      suspectedDrugToxicity: false,
      suspectedOpioidDeath: false,
      infectiousDisease: false,
      bodyLocationAddress: null,
      locationType: null,
      isTentative: false,
      isUnidentified: false,
      isNonHuman: false,
      firstName: '',
      middleName: '',
      lastName: '',
      callerLastName: '',
      callerFirstName: '',
      callerNumber: '',
      agencyType: null,
      agency: null,
      callerRelationship: null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      createCase(values);
    },
  });

  // This changes formik's default behavior from validating on every input
  // to validation 200ms after the last input, which should improve
  // performance both locally and in the deployed versions
  useDebouncedValidate({
    validate: values => {
      formik?.validateForm(values);
    },
    debounceTime: 200,
    values: formik?.values,
  });

  const fetchLocationType = async () => {
    fetch(REACT_APP_API_URL + 'getaddresstypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setLocationTypeOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchInvestigationReasonOptions = async () => {
    fetch(REACT_APP_API_URL + 'getinvestigationreasonoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setInvestigationReasonOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  // INITIALIZING PAGE STATE
  useEffect(() => {
    // INITIALIZE JURISDICTION OPTIONS
    setJurisdictionOptions(auth?.user?.jdxAccessList?.sort((a, b) => a?.jdxName > b?.jdxName));

    // INITIALIZE AGENCY TYPE OPTIONS
    fetch(REACT_APP_API_URL + 'getagencytypeoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setAgencyTypeOptions(data);
      });

    // INITIALIZE RELATIONSHIP OPTIONS
    fetch(REACT_APP_API_URL + 'getrelationshipoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCallerRelationshipOptions(data);
      });

    fetchInvestigationReasonOptions();
    fetchLocationType();
    // VALIDATE THE FORM ON PAGE LOAD
    formik?.validateForm();
  }, []);

  // CUSTOM FORM UPDATE HANDLERS
  const handleAgencyTypeChange = async (event, data) => {
    formik?.setFieldValue('agencyType', data);
    formik?.setFieldValue('agency', null);
    setAgencyOptions(await getAgencyOptions(data, formik?.values?.jurisdiction));
  };

  const handleJurisdictionChange = async (event, data) => {
    formik?.setFieldValue('jurisdiction', data);
    if (agencyType !== null)
      setAgencyOptions(await getAgencyOptions(formik?.values?.agencyType, data));
  };

  const handlePreferredCaseIdChange = event => {
    formik?.setFieldValue('preferredCaseId', event.target.value);

    if (auth?.user?.jdxAccessList?.find(jdx => jdx?.jdxCode === event.target.value.substring(0, 2)))
      handleJurisdictionChange(
        null,
        auth?.user?.jdxAccessList?.find(jdx => jdx?.jdxCode === event.target.value.substring(0, 2))
      );
    else handleJurisdictionChange(null, null);
  };

  // HANDLE SUBMIT ANYWAY CHANGES
  useEffect(() => {
    if (submitAnyway) {
      formik.handleSubmit();
    }
  }, [submitAnyway]);

  useEffect(() => {
    if (!similarCasesDialogOpen) {
      setPreSubmitLoading(false);
    }
  }, [similarCasesDialogOpen]);

  // CREATE CASE HANDLER
  const createCase = async values => {
    await setPreSubmitLoading(true);

    if (!submitAnyway) {
      // Check if decedent was recently entered into the system
      var similarNamesFound = false;

      await fetch(
        `${REACT_APP_API_URL}checkifcaseexistsfromname?firstName=${values.firstName}&lastName=${values.lastName}&numDays=10`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
        }
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          setSimilarCasesData(data);
          setSimilarCasesDialogOpen(true);
          if (data.length > 0) similarNamesFound = true;
        })
        .catch(e => {});

      if (similarNamesFound) return;
    }

    // API PARAMS
    var caseReporterTypes = {
      'Nursing Home': '3b758606-75d6-4068-a66d-21176a9f1c5f',
      'Funeral Home': 'dc11f527-4cb4-4df8-9b20-22f1f2731c6e',
      'Medical Facility': '875c36e0-a559-4552-ba14-2c0f8368a632',
      'Law Enforcement': '85115ed1-039f-4b89-b15c-cfcb4f6046d3',
      Other: '25439ade-f997-451a-9122-2c904dad52d9',
    };

    var caseParams = {
      CreatedByUserSeq: auth?.user?.userSeq,
      CaseId: values?.preferredCaseId,
      CaseLoadedDateTime: historicDataEntryUser
        ? null
        : dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      IsUnidentified: values?.isUnidentified,
      IsTentative: values?.isTentative,
      IsNonHuman: values?.isNonHuman,
    };

    var caseReporterParams = {
      JdxSeq: values?.jurisdiction?.jdxSeq,
      CaseReporterTypeSeq: caseReporterTypes[values?.agencyType?.optionName],
      FuneralHomeSeq:
        values?.agencyType?.optionSeq?.toUpperCase() ===
        '6172b365-983d-4b30-97b0-fb5221a314e0'.toUpperCase()
          ? values?.agency?.optionSeq
          : null,
      NursingHomeSeq:
        values?.agencyType?.optionSeq?.toUpperCase() ===
        '9da66840-2a60-4e1c-87bc-ee0d02ae199c'.toUpperCase()
          ? values?.agency?.optionSeq
          : null,
      HcfSeq:
        values?.agencyType?.optionSeq?.toUpperCase() ===
        '348bd197-50a3-4daf-82d5-862c00e51bcb'.toUpperCase()
          ? values?.agency?.optionSeq
          : null,
      LawEnforcementSeq:
        values?.agencyType?.optionSeq?.toUpperCase() ===
        '22cd6ec5-93c6-4344-a859-e3daf99797bd'.toUpperCase()
          ? values?.agency?.optionSeq
          : null,
      ReporterOther:
        values?.agencyType?.optionSeq?.toUpperCase() ===
        '87865768-98d6-42e6-92ff-cbe63cb9b107'.toUpperCase()
          ? values?.agency
          : null,
    };

    var decedentPersonParams = {
      FirstName: values?.firstName,
      MiddleName: values?.middleName,
      LastName: values?.lastName,
    };

    var callerPersonParams = {
      FirstName: values?.callerFirstName,
      LastName: values?.callerLastName,
    };

    var callerContactItemParams = {
      ContactItemTypeSeq: '176240f7-30a9-40dd-af0c-304226e1bfea',
      ContactItemDetails: values?.callerNumber,
    };

    var callerNextOfKinParams = {
      RelshipSeq: values?.callerRelationship?.optionSeq,
    };

    const deathInvestigationParams = {
      IsDrugToxicity: values?.suspectedDrugToxicity,
      IsInfectiousDisease: values?.infectiousDisease,
      IsOpioid: values?.suspectedOpioidDeath,
      InvestigationReason: values?.investigationReason
    };

    let blAddressParams = (await getBLAddressParams(values?.bodyLocationAddress)) || {};

    if (values?.locationTypeSeq) {
      blAddressParams = {
        ...blAddressParams,
        LocationTypeSeq: values?.locationTypeSeq,
      };
    }

    // PARSE PARAMS INTO FORM DATA AS JSON STRINGS
    const formData = new FormData();
    formData.append('CaseParams', JSON.stringify(caseParams));
    formData.append('CaseReporterParams', JSON.stringify(caseReporterParams));
    formData.append('DeathInvestigationParams', JSON.stringify(deathInvestigationParams));
    formData.append('BLAddressParams', JSON.stringify(blAddressParams));
    formData.append('DecedentPersonParams', JSON.stringify(decedentPersonParams));
    formData.append('CallerPersonParams', JSON.stringify(callerPersonParams));
    formData.append('CallerContactItemParams', JSON.stringify(callerContactItemParams));
    formData.append('CallerNextOfKinParams', JSON.stringify(callerNextOfKinParams));
    setSubmitting(true);
    await submitCase(formData);
  };

  const [displayPhoneNumber, setDisplayPhoneNumber] = useState('');

  const formatPhoneNumber = value => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = event => {
    const { value } = event.target;
    // Store the raw input in formik
    formik.setFieldValue('callerNumber', value.replace(/[^\d]/g, ''));
    // Update the display value with formatting
    setDisplayPhoneNumber(formatPhoneNumber(value));
  };

  const submitCase = async formData => {
    // CALL CREATE CASE API
    const response = await fetch(REACT_APP_API_URL + 'CreateCase', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + auth?.user?.token,
      },
      body: formData,
    });

    if (response.status == 401) {
      setErrorResponse('You are unauthorized to use this tool');
      throw new Error('You are unauthorized to use this tool');
    } else if (response.status >= 400) {
      setErrorResponse('An error occured while submitting the case');
      throw new Error('An error occured while submitting the case');
    }

    const newCaseId = await response.text();
    setNewCaseId(newCaseId);
  };

  if (submitting) {
    return <__QuickCreatingCaseView error={errorResponse} newCaseID={newCaseId} />;
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Card
          sx={{
            h: 'max-content',
            mx: 'auto',
            my: 3,
            width: { xs: '95%', md: '75%' },
          }}
          raised
        >
          <CardHeader
            title='New Case'
            sx={{
              mx: 'auto',
              textAlign: 'center',
              color: theme.palette.primary.main,
            }}
          />

          <CardContent>
            {/* JURISDICTION */}
            <Grid container spacing={2}>
              <Grid
                item
                xs
                sx={{
                  display: roleCharacteristicsCheck(
                    auth?.user?.specialRoleCharacteristics,
                    'DataEntryOperator'
                  )
                    ? 'none'
                    : 'block',
                }}
              >
                <Autocomplete
                  {...formik?.getFieldProps('jurisdiction')}
                  id='jurisdiction'
                  name='jurisdiction'
                  options={jurisdictionOptions}
                  value={formik?.values?.jurisdiction}
                  isOptionEqualToValue={(option, value) =>
                    option?.jdxSeq?.toUpperCase() === value?.jdxSeq?.toUpperCase()
                  }
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.jurisdiction && Boolean(formik?.errors?.jurisdiction)}
                  helperText={formik?.touched?.jurisdiction && formik?.errors?.jurisdiction}
                  size='small'
                  required
                  getOptionLabel={getJurisdictionOptionLabel}
                  onChange={handleJurisdictionChange}
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Jurisdiction'
                      placeholder='Jurisdiction'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs
                sx={{
                  display: roleCharacteristicsCheck(
                    auth?.user?.specialRoleCharacteristics,
                    'DataEntryOperator'
                  )
                    ? 'block'
                    : 'none',
                }}
              >
                <TextField
                  {...formik?.getFieldProps('preferredCaseId')}
                  id='preferredCaseId'
                  name='preferredCaseId'
                  value={formik?.values?.preferredCaseId}
                  onChange={handlePreferredCaseIdChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.preferredCaseId && Boolean(formik?.errors?.preferredCaseId)
                  }
                  helperText={formik?.touched?.preferredCaseId && formik?.errors?.preferredCaseId}
                  size='small'
                  variant='outlined'
                  label='CaseID'
                  fullWidth
                  required={roleCharacteristicsCheck(
                    auth?.user?.specialRoleCharacteristics,
                    'DataEntryOperator'
                  )}
                />
              </Grid>
            </Grid>

            <br />

            {/* DEMOGRAPHIC INFO */}
            <Typography variant='subtitle1' color='gray'>
              <strong>Decedent Details</strong>
            </Typography>
            <Divider sx={{ mb: 1, opacity: 1 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  checked={Boolean(formik.values?.isTentative)}
                  onChange={e => formik.setFieldValue('isTentative', e.target.checked)}
                  control={<Checkbox />}
                  label={<span style={{ fontSize: '0.75rem' }}>Tentative</span>}
                  style={{ marginRight: '16px' }}
                />
                <FormControlLabel
                  checked={Boolean(formik.values?.isUnidentified)}
                  onChange={e => {
                    const isChecked = e.target.checked;
                    formik.setFieldValue('isUnidentified', isChecked);

                    // Only set 'lastName' to 'Unidentified' if checkbox is checked and 'lastName' is empty
                    if (isChecked && !formik.values?.lastName) {
                      formik.setFieldValue('lastName', 'Unidentified');
                    }
                  }}
                  control={<Checkbox />}
                  label={<span style={{ fontSize: '0.75rem' }}>Unidentified</span>}
                  style={{ marginRight: '16px' }}
                />

                <FormControlLabel
                  checked={Boolean(formik.values?.isNonHuman)}
                  onChange={e => {
                    const isChecked = e.target.checked;
                    formik.setFieldValue('isNonHuman', isChecked);

                    if (isChecked && !formik.values?.lastName) {
                      formik.setFieldValue('lastName', 'REMAINS');
                    }

                    if (isChecked && !formik.values?.firstName) {
                      formik.setFieldValue('firstName', 'NON HUMAN');
                    }
                  }}
                  control={<Checkbox />}
                  label={<span style={{ fontSize: '0.75rem' }}>NonHuman</span>}
                />
              </Grid>
              <Grid item xs={12} md>
                <TextField
                  id='lastName'
                  name='lastName'
                  value={formik?.values?.lastName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.lastName && Boolean(formik?.errors?.lastName)}
                  helperText={formik?.touched?.lastName && formik?.errors?.lastName}
                  size='small'
                  variant='outlined'
                  label='Decedent Last name'
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md>
                <TextField
                  id='firstName'
                  name='firstName'
                  value={formik?.values?.firstName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.firstName && Boolean(formik?.errors?.firstName)}
                  helperText={formik?.touched?.firstName && formik?.errors?.firstName}
                  size='small'
                  variant='outlined'
                  label='Decedent First name'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md>
                <TextField
                  id='middleName'
                  name='middleName'
                  value={formik?.values?.middleName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.middleName && Boolean(formik?.errors?.middleName)}
                  helperText={formik?.touched?.middleName && formik?.errors?.middleName}
                  size='small'
                  variant='outlined'
                  label='Decedent middle name'
                  fullWidth
                />
              </Grid>
            </Grid>

            <br />

            {/* CALLER INFO */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='subtitle1' color='gray'>
                  <strong>Case Reporter</strong>
                </Typography>
                <Divider sx={{ opacity: 1 }} />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id='callerLastName'
                  name='callerLastName'
                  value={formik?.values?.callerLastName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={formik?.touched?.callerLastName && Boolean(formik?.errors?.callerLastName)}
                  helperText={formik?.touched?.callerLastName && formik?.errors?.callerLastName}
                  size='small'
                  variant='outlined'
                  label='Last name'
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id='callerFirstName'
                  name='callerFirstName'
                  value={formik?.values?.callerFirstName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.callerFirstName && Boolean(formik?.errors?.callerFirstName)
                  }
                  helperText={formik?.touched?.callerFirstName && formik?.errors?.callerFirstName}
                  size='small'
                  variant='outlined'
                  label='First name'
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Autocomplete
                  id='callerRelationship'
                  name='callerRelationship'
                  options={callerRelationshipOptions}
                  value={formik?.values?.callerRelationship}
                  size='small'
                  getOptionLabel={option => option?.optionName}
                  isOptionEqualToValue={(option, value) =>
                    option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                  }
                  onChange={(e, value) => formik?.setFieldValue('callerRelationship', value)}
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Relationship to Decedent'
                      placeholder='Relationship to Decedent'
                      onBlur={formik?.handleBlur}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id='callerNumber'
                  name='callerNumber'
                  value={displayPhoneNumber}
                  onChange={handlePhoneChange}
                  onBlur={e => {
                    formik.handleBlur(e);
                    setDisplayPhoneNumber(formatPhoneNumber(formik.values.callerNumber));
                  }}
                  error={formik.touched.callerNumber && Boolean(formik.errors.callerNumber)}
                  helperText={formik.touched.callerNumber && formik.errors.callerNumber}
                  size='small'
                  variant='outlined'
                  label='Contact number'
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 12, // 10 digits + 2 hyphens
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Autocomplete
                  id='agencyType'
                  name='agencyType'
                  options={agencyTypeOptions}
                  value={formik?.values?.agencyType}
                  size='small'
                  getOptionLabel={option => option?.optionName}
                  isOptionEqualToValue={(option, value) =>
                    option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                  }
                  onChange={handleAgencyTypeChange}
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Type of Agency/Facility'
                      placeholder='Type of Agency/Facility'
                      onBlur={formik?.handleBlur}
                      error={formik?.touched?.agencyType && Boolean(formik?.errors?.agencyType)}
                      helperText={formik?.touched?.agencyType && formik?.errors?.agencyType}
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                {formik?.values?.agencyType != null &&
                formik?.values?.agencyType?.optionSeq ==
                  '87865768-98d6-42e6-92ff-cbe63cb9b107'.toUpperCase() ? (
                  <TextField
                    id='agency'
                    name='agency'
                    value={formik?.values?.agency}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    size='small'
                    variant='outlined'
                    label='Name of Agency/Facility'
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    id='agency'
                    name='agency'
                    options={agencyOptions}
                    value={formik?.values?.agency}
                    isOptionEqualToValue={(option, value) =>
                      option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                    }
                    size='small'
                    getOptionLabel={option => option?.optionName}
                    onChange={(e, value) => formik?.setFieldValue('agency', value)}
                    fullWidth
                    disabled={formik?.values?.agencyType === null}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Name of Agency/Facility'
                        placeholder='Name of Agency/Facility'
                        onBlur={formik?.handleBlur}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={3}>
                <Autocomplete
                  id='investigationReason'
                  name='investigationReason'
                  options={investigationReasonOptions}
                  value={formik?.values?.investigationReason}
                  size='small'
                  getOptionLabel={option => option?.optionName}
                  isOptionEqualToValue={(option, value) =>
                    option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                  }
                  onChange={(e, value) => formik?.setFieldValue('investigationReason', value)}
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Reason for Investigation'
                      placeholder='Reason for Investigation'
                      onBlur={formik?.handleBlur}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <br />

            <Typography variant='subtitle1' color='gray'>
              <strong>Real-time monitoring</strong>
            </Typography>
            <Divider sx={{ mb: 1, opacity: 1 }} />

            {/* SUSPECTED DRUG FLAGS */}
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  label='Suspected Drug Toxicity?'
                  control={
                    <Checkbox
                      id='suspectedDrugToxicity'
                      name='suspectedDrugToxicity'
                      checked={formik?.values?.suspectedDrugToxicity}
                      value={formik?.values?.suspectedDrugToxicity}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={
                        formik?.touched?.suspectedDrugToxicity &&
                        Boolean(formik?.errors?.suspectedDrugToxicity)
                      }
                      helperText={
                        formik?.touched?.suspectedDrugToxicity &&
                        formik?.errors?.suspectedDrugToxicity
                      }
                    />
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  label='Suspected Opioid Death?'
                  control={
                    <Checkbox
                      id='suspectedOpioidDeath'
                      name='suspectedOpioidDeath'
                      checked={formik?.values?.suspectedOpioidDeath}
                      value={formik?.values?.suspectedOpioidDeath}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={
                        formik?.touched?.suspectedOpioidDeath &&
                        Boolean(formik?.errors?.suspectedOpioidDeath)
                      }
                      helperText={
                        formik?.touched?.suspectedOpioidDeath &&
                        formik?.errors?.suspectedOpioidDeath
                      }
                    />
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  label='Infectious Disease?'
                  control={
                    <Checkbox
                      id='infectiousDisease'
                      name='infectiousDisease'
                      checked={formik?.values?.infectiousDisease}
                      value={formik?.values?.infectiousDisease}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      error={
                        formik?.touched?.infectiousDisease &&
                        Boolean(formik?.errors?.infectiousDisease)
                      }
                      helperText={
                        formik?.touched?.infectiousDisease && formik?.errors?.infectiousDisease
                      }
                    />
                  }
                />
              </Grid>
            </Grid>

            {/* SUBMIT */}
            <LoadingButton
              color='primary'
              variant='contained'
              fullWidth
              type='submit'
              sx={{ my: 2 }}
              disabled={!formik?.isValid}
              loading={preSubmitLoading}
            >
              GENERATE CASE
            </LoadingButton>

            {/* BODY LOCATION MAP */}
            <Collapse
              in={
                formik.values?.suspectedDrugToxicity ||
                formik.values?.suspectedOpioidDeath ||
                formik.values?.infectiousDisease
              }
            >
              <Grid container alignItems='center' spacing={2}>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Autocomplete
                    id='locationType'
                    name='locationType'
                    options={locationTypeOptions}
                    value={formik?.values?.locationType}
                    size='small'
                    getOptionLabel={option => option?.optionName}
                    isOptionEqualToValue={(option, value) =>
                      option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
                    }
                    onChange={(e, value) => formik?.setFieldValue('locationType', value)}
                    fullWidth
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Location Type'
                        placeholder='Location Type'
                        onBlur={formik?.handleBlur}
                        error={
                          formik?.touched?.locationType && Boolean(formik?.errors?.locationType)
                        }
                        helperText={formik?.touched?.locationType && formik?.errors?.locationType}
                      />
                    )}
                  />
                </Grid>

                <Tooltip
                  sx={{
                    color: 'black',
                    position: 'relative',
                    ml: 'auto',
                    top: 30,
                    right: 20,
                    left: 'auto',
                    zIndex: 1000,
                  }}
                  size='small'
                  title='It is recommended to enter a scene location for cases with a real-time monitoring flag (suspected drug death, suspected opioid death or infectious disease)'
                >
                  <InfoIcon />
                </Tooltip>

                <Grid item xs={12} sx={{ mt: -3 }}>
                  <MapContainer
                    style={{ width: '100%', height: 350 }}
                    center={initialCenter}
                    zoom={initialZoom}
                    ref={mapRef}
                    zoomControl={false}
                  >
                    <TileLayer
                      url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                      attribution={
                        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                      }
                    />

                    <MapSearchBar
                      blAddressInput={bodyLocationAddressInput}
                      setBlAddressInput={setBodyLocationAddressInput}
                      blAddressOptions={bodyLocationAddressOptions}
                      setBlAddressOptions={setBodyLocationAddressOptions}
                      jurisdictionOptions={jurisdictionOptions}
                    />
                  </MapContainer>
                </Grid>
              </Grid>
            </Collapse>
          </CardContent>
        </Card>

        <SimilarCaseDialog
          open={similarCasesDialogOpen}
          setOpen={setSimilarCasesDialogOpen}
          setSubmitAnyway={setSubmitAnyway}
          caseData={similarCasesData}
        />
      </Form>
    </FormikProvider>
  );
}
