import { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthService';

const { REACT_APP_API_URL } = process.env;

export const fetchGlobalAppSetting = async ({ settingName = '', accessToken = '' }) => {
  try {
    const response = await fetch(
      `${REACT_APP_API_URL}GlobalAppSettings/getglobalappsetting/${settingName}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error(`Failed to fetch setting: ${error.message}`);
  }
};

export function useGlobalAppSetting(settingName: string) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [setting, setSetting] = useState<string | null>(null);

  useEffect(() => {
    const getSetting = async () => {
      try {
        const result = await fetchGlobalAppSetting({
          settingName,
          accessToken: user?.accessToken || '',
        });
        setSetting(result.value);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    if (user?.accessToken) {
      getSetting();
    }
  }, [settingName, user?.accessToken]);

  return { setting, loading, error };
}
