import { Box } from '@mui/material';
import { useState } from 'react';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';
import AccountRequestView from './AccountRequestView';

export default function SupportAgentView() {
  const [currentTab, setCurrentTab] = useState('0');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  const drawerContents = [
    {
      id: 'account-requests',
      text: 'Account Requests',
      label: 'Account Requests',
      page: <AccountRequestView handleMenuClick={handleMenuClick} />,
    },
  ];

  return (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <TreeTabPanel value={currentTab} index={currentTab}>
          {drawerContents?.find(drawerItem => drawerItem.id === currentTab)?.page ??
            drawerContents[0].page}
        </TreeTabPanel>
      </Box>
    </Box>
  );
}
