import { Box, Grid, Stack, Button } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';

function getValueByNestedKey(object, key) {
  const keys = key.split('.');
  return keys.reduce((obj, k) => obj?.[k], object);
}

export function ActionList(props) {
  const { onSetToday, onClear, onAccept, className } = props;

  const actions = [
    { text: 'Today', method: onSetToday, color: 'primary' },
    { text: 'Clear', method: onClear, color: 'error' },
    { text: 'Save', method: onAccept, color: 'secondary' }
  ];

  return (
    // Propagate the className such that CSS selectors can be applied
    <Stack direction='row' spacing={1} sx={{ m: 1 }} className={className}>
      {actions.map(({ text, method, color }) => (
        <Button sx={{ p: 1 }} color={color} onClick={method}>
          {text}
        </Button>
      ))}
    </Stack>
  );
}

export default function CaseViewDateTimeField({
  xs,
  sm,
  md,
  lg,
  xl,
  authorizedToEdit,
  fieldName,
  label,
  sx,
  error = false,
  bypassEditing = false,
  helperText = '',
}) {
  const formik = useFormikContext();
  const fieldValue = formik?.values && getValueByNestedKey(formik?.values, fieldName);
  const fieldTouched = formik?.touched && getValueByNestedKey(formik?.touched, fieldName);
  const fieldErrors = formik?.errors && getValueByNestedKey(formik?.errors, fieldName);
  const { setUnsavedChanges } = useUnsavedChanges();


  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      sx={sx}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            width: '100%',
          }}
        >
          <DateTimePicker
            disabled={
              !bypassEditing && (!formik?.status?.editing || !authorizedToEdit)
            }
            fullWidth
            name={fieldName}
            label={label}
            ampm={false}
            value={fieldValue !== null ? dayjs(fieldValue) : null}
            onChange={date => {
              const localDate = date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null;
              formik.setFieldValue(fieldName, localDate);
              setUnsavedChanges();    
            }}
            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
            slotProps={{
              textField: {
                id: { fieldName },
                error: Boolean(error || (fieldTouched && Boolean(fieldErrors))),
                helperText: (helperText || (fieldTouched ? fieldErrors?.toString() : ''))
              },
              field: { clearable: true },
            }}
            slots={{
              actionBar: ActionList,
            }}
            sx={{ width: '100%' }}
            format='L HH:mm'
          />
        </Box>
      </LocalizationProvider>
    </Grid>
  );
}
