import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-premium';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import JurisdictionAutocomplete from '../../components/JurisdictionAutocomplete';
import { Jurisdiction } from '../../types/Jurisdiction.interface';
import { useAuth } from '../../utils/auth/AuthService';
import CustomPagination from '../../utils/components/CustomPagination';

const GridToolbar = ({ title, casecount, count }) => {
  const getTitleAndCount = () => {
    var titleAndCount = '';
    if (title != null && casecount === true) titleAndCount = title + ': ' + count;
    else if (title != null && casecount != null) titleAndCount = title + ': ' + casecount;
    else if (title != null && casecount == null) titleAndCount = title;
    else if (title == null && casecount === true) titleAndCount += 'Cases Found: ' + count;
    else if (title == null && casecount != null) titleAndCount += 'Cases Found: ' + casecount;
    return titleAndCount;
  };

  return (
    <GridToolbarContainer>
      <Stack sx={{ width: '100%' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ p: 2 }}>
          <Typography variant='h5'>{getTitleAndCount()}</Typography>
          <GridToolbarExport />
        </Stack>
        <Divider sx={{ opacity: 0.6 }} />
      </Stack>
    </GridToolbarContainer>
  );
};

export interface JurisdictionCase {
  caseID?: string;
  decedentLastName?: string;
  decedentFirstName?: string;
  age?: string;
  decedentRace?: string;
  decedentGender?: string;
  dateOfDeath?: Date | null;
  placeOfDeath?: string;
  placeOfDeathCity?: string;
  decedentResidenceCity?: string;
  jurisdiction?: string;
  examType?: string;
  examStartedOn?: Date | null;
  examPhysician?: string;
  mannerOfDeath?: string;
}

const MasterCaseListView: React.FC = () => {
  const [cases, setCases] = useState<JurisdictionCase[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 150,
    page: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<Jurisdiction[]>(
    user?.jdxAccessList ?? []
  );

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const fetchRowCount = async () => {
    try {
      const response = await fetch(REACT_APP_API_URL + 'GetJurisdictionCaseCount', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedJurisdictions),
      });
      const count = parseInt(await response.text());
      setTotalRows(count);
    } catch (error) {
      console.error('Error fetching row count:', error);
    }
  };

  const fetchCases = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}GetAllJurisdictionCases?offset=${
          paginationModel.page * paginationModel.pageSize
        }&limit=${paginationModel.pageSize}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedJurisdictions),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch cases');
      }

      const data = await response.json();
      setCases(data);
    } catch (error) {
      console.error('Error fetching cases:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRowCount();
  }, [selectedJurisdictions]);

  useEffect(() => {
    fetchCases();
  }, [paginationModel, selectedJurisdictions]);

  const columns: GridColDef[] = [
    {
      field: 'caseID',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => (
        <Button
          variant='text'
          color='primary'
          onClick={event => {
            handleCaseClick(event, params);
          }}
          style={{ fontFamily: '"DataGrid", monospace', fontSize: '0.875rem' }}
        >
          <b>{params.formattedValue}</b>
        </Button>
      ),
    },
    {
      field: 'decedentLastName',
      headerName: 'Decedent Last Name',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'decedentFirstName',
      headerName: 'Decedent First Name',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 100,
      editable: false,
    },
    {
      field: 'decedentRace',
      headerName: 'Race',
      minWidth: 100,
      flex: 1,
      editable: false,
    },
    {
      field: 'decedentGender',
      headerName: 'Gender',
      width: 100,
      editable: false,
    },
    {
      field: 'dateOfDeath',
      headerName: 'Date of Death',
      width: 140,
      editable: false,
      type: 'date',
      valueFormatter: value => (value ? format(new Date(value), 'MM/dd/yyyy') : null),
    },
    {
      field: 'placeOfDeath',
      headerName: 'Place of Death',
      minWidth: 180,
      flex: 1,
      editable: false,
    },
    {
      field: 'placeOfDeathCity',
      headerName: 'City of Death',
      minWidth: 140,
      flex: 1,
      editable: false,
    },
    {
      field: 'decedentResidenceCity',
      headerName: 'Residence City',
      minWidth: 140,
      flex: 1,
      editable: false,
    },
    {
      field: 'jurisdiction',
      headerName: 'Jurisdiction',
      width: 140,
      editable: false,
    },
    {
      field: 'examType',
      headerName: 'Exam Type',
      width: 120,
      editable: false,
    },
    {
      field: 'examStartedOn',
      headerName: 'Exam Started On',
      width: 140,
      editable: false,
      type: 'dateTime',
      valueFormatter: value => (value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null),
    },
    {
      field: 'examPhysician',
      headerName: 'Exam Physician',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'mannerOfDeath',
      headerName: 'Manner of Death',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
  ];

  return (
    <Stack spacing={2}>
      <JurisdictionAutocomplete
        selectedJurisdictions={selectedJurisdictions}
        setSelectedJurisdictions={setSelectedJurisdictions}
      />

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 500,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <DataGridPremium
          rows={cases}
          columns={columns}
          rowCount={totalRows}
          pagination
          pageSizeOptions={[5]}
          getRowId={row => row.caseID}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          paginationMode='server'
          onPaginationModelChange={setPaginationModel}
          loading={loading}
          slots={{
            pagination: CustomPagination,
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              title: null,
              casecount: totalRows,
              count: totalRows,
              expanded: false,
              setExpanded: () => {},
            },
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          sx={{
            height: 700,
            '& .MuiDataGrid-cell': {
              fontSize: '0.775rem',
              borderRight: '1px solid rgba(200, 200, 200, 0.6)',
            },
            '& .MuiDataGrid-row': {
              borderBottom: '1px solid rgba(200, 200, 200, 0.6)',
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default MasterCaseListView;
