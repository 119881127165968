import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import React from 'react';
import { useNavigate } from 'react-router';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { DecedentNameCell } from './DecedentNameCell';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
import { format } from 'date-fns';
import { PrintFileButton } from '../../utils/functions/triggerPrintDialog';
import { useAppSettings } from '../../Context/AppSettingsContext';


export function OverduePathologyReportsDataGrid({ data, isLoading }: DashboardDataGridProps) {
  const navigate = useNavigate();
  const { getAppSettingByKey } = useAppSettings();
  const caseOverDueThreshold  = getAppSettingByKey('CaseOverDueThreshold');

  const handleCaseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    event.stopPropagation();
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };


  const renderFileListCell = (params: any) => {
    if (Array.isArray(params.value) && params.value?.length > 0) {
      return (
        <div>
          {
            // downloading ? (<div>Downloading...</div>
            //  ) : (
            params.value?.map((file: any, index: any) => (
              <div key={index}>
                <PrintFileButton
                  fileSeq={file.fileseq}
                  filename={file.filename}
                  caseid={params.row.caseid}
                />
              </div>
            ))
            // )
          }
        </div>
      );
    } else {
      return null;
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'caseid',
      headerName: 'CaseID',
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: params => <DecedentNameCell {...params} />,
    },
    {
      field: 'pathologist',
      headerName: 'Pathologist',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'overduedays',
      headerName: 'Days Overdue',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'menotified',
      headerName: 'Reported',
      width: 140,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
        field: 'ancillaryReports',
        headerName: 'Ancillary Reports',
        flex: 1,
        width: 200,
        editable: false,
        align: 'left',
        headerAlign: 'left',
        headerClassName: 'header-theme',
        renderCell: renderFileListCell,
      },
  ];

  return (
    <SiloDataGrid
      loading={isLoading}
      listresults={data}
      columnsInput={columns}
      idcolumn='caseid'
      gridheight={200}
      gridcolor='red'
      title='Overdue Pathology Reports'
      tooltip={`Cases with Pathology Reports that are overdue or approaching the deadline. The threshold is set at ${caseOverDueThreshold} days since the case was reported`}
      casecount
      rowsperpage={50}
      filterModel={undefined}
      columnsVisibility={undefined}
    />
  );
}
