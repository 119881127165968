import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import CasesCreatedByGeneralStaffView from './views/Reports/AdminReports/CasesCreatedByGeneralStaffView';
import CasesPastDueView from './views/Reports/AdminReports/CasesPastDueView';
import DataEntryResponseTimeView from './views/Reports/AdminReports/DataEntryResponseTimeView';
import DrugToxAuditView from './views/Reports/AdminReports/DrugToxAuditView';
import InvestigatorCaseActivityReportView from './views/Reports/AdminReports/InvestigatorCaseActivityReportView';
import METurnaroundTimeView from './views/Reports/AdminReports/METurnaroundTimeView';
import MissingCaseDocumentsView from './views/Reports/AdminReports/MissingCaseDocumentsView';
import MissingDemographicsView from './views/Reports/AdminReports/MissingDemographicsView';
import MissingReasonforInvestigationView from './views/Reports/AdminReports/MissingReasonforInvestigationView';
import MorgueTechCaseActivityView from './views/Reports/AdminReports/MorgueTechCaseActivityView';
import OpenAdminAssistantCasesView from './views/Reports/AdminReports/OpenAdminAssistantCasesView';
import OpenPathologistCasesView from './views/Reports/AdminReports/OpenPathologistCasesView';
import PathologistActivityReportView from './views/Reports/AdminReports/PathologistActivityReportView';
import SceneExaminedByView from './views/Reports/AdminReports/SceneExaminedByView';
import ApprovedReleaseCasesByPathologistView from './views/Reports/BusinessReports/ApprovedReleaseCasesByPathologistView.jsx';
import CasePaymentsReportView from './views/Reports/BusinessReports/CasePaymentsReportView';
import ClosedCasesByPathologistView from './views/Reports/BusinessReports/ClosedCasesByPathologistView';
import CompletedPathologistExamsView from './views/Reports/BusinessReports/CompletedPathologistExamsView';
import ExamReportUploadedByPathologistReportView from './views/Reports/BusinessReports/ExamReportUploadedByPathologistReportView';
import LabTestingTagsReportView from './views/Reports/BusinessReports/LabTestingTagsReportView';
import PIICaseSearchView from './views/Reports/GeneralReports/PIICaseSearchView';
import ReasonForInvestigationView from './views/Reports/GeneralReports/ReasonForInvestigationView';

import { Box } from '@mui/material';
import CaseViewTesting from './components/CaseView/CaseViewHeader';
import { ProtectedRoute } from './utils/auth/ProtectedRoute';
import CaseView from './views/Case/CaseView';
import DeathInvestigationsSummaryView from './views/Reports/BusinessReports/DeathInvestigationsBreakdownView';
import AnalyteDetectedView from './views/Reports/GeneralReports/AnalyteDetectedView';
import CaseResponseTimeView from './views/Reports/GeneralReports/CaseResponseTimeView';
import CaseTurnAroundTimeView from './views/Reports/GeneralReports/CaseTurnAroundTimeView';
import CasesNoNOKReportView from './views/Reports/GeneralReports/CasesNoNOKReportView';
import DrugToxicityView from './views/Reports/GeneralReports/DrugToxView';
import ExamsSummaryReportView from './views/Reports/GeneralReports/ExamsSummaryReportView';
import HomicideView from './views/Reports/GeneralReports/HomicideChartView';
import MEActionActivityReportView from './views/Reports/GeneralReports/MEActionActivityReportView';
import MonthlySuspectedDrugsView from './views/Reports/GeneralReports/MonthlySuspectedDrugsView';
import OpioidDeathView from './views/Reports/GeneralReports/OpioidDeathView';
import SuicideView from './views/Reports/GeneralReports/SuicideChartView';
import UnclaimedCasesView from './views/Reports/GeneralReports/UnclaimedCasesView';
import ViolentDeathView from './views/Reports/GeneralReports/ViolentDeathView';
import ReportsView from './views/Reports/ReportsView';

import CaseIntakeView from './views/Case/CaseIntakeView';
import __QuickCreatingCaseView from './views/Case/__QuickCreatingCaseView';

import CMSAdminView from './views/CMSAdmin/CMSAdminView';
import DailyExamListView from './views/Case/DailyExamListView';
import CaseSearchView from './views/CaseSearchView';
import QuickCaseSearchView from './views/QuickCaseSearchView';

import HomeView from './views/Home/HomeView';
import LMSAdminView from './views/LMSAdmin/LMSAdminView';
import { LoginForm } from './views/Login/LoginForm';
import { LoginView } from './views/Login/LoginView';

import { ForgotPasswordCodeForm } from './views/Login/ForgotPassword/ForgotPasswordCodeForm';
import { ForgotPasswordForm } from './views/Login/ForgotPassword/ForgotPasswordForm';
import { ForgotPasswordView } from './views/Login/ForgotPassword/ForgotPasswordView';
import { NewPasswordForm } from './views/Login/ForgotPassword/NewPasswordForm';

import { TwoFactorCodeForm } from './views/Login/TwoFactor/TwoFactorCodeForm';
import { TwoFactorMethodSelector } from './views/Login/TwoFactor/TwoFactorMethodSelector';
import { TwoFactorView } from './views/Login/TwoFactor/TwoFactorView';

import ResourcesView from './views/Resources/ResourcesView';
import SecurityAdminView from './views/SecurityAdmin/SecurityAdminView';
import SysAdminHomeView from './views/SysAdmin/SysAdminHomeView';
import ServerMonitoringDashboard from './views/SysAdmin/SysAdminModules/ServerMonitoringDashboard/ServerMonitoringDashboard';

//Admin

//Users
import AddUser from './views/SysAdmin/SysAdminModules/UserAdminModules/AddUser';
import Documents from './views/SysAdmin/SysAdminModules/UserAdminModules/Documents';
import LoginHistory from './views/SysAdmin/SysAdminModules/UserAdminModules/LoginHistory';
import Notes from './views/SysAdmin/SysAdminModules/UserAdminModules/Notes';

// Reference Data Routes
import ReferenceMasterTableType1 from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableType1View/ReferenceMasterTableType1View';
import ReferenceMasterTableType2 from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableType2View/ReferenceMasterTableType2View';
import ClothingType from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/ClothingType/ClothingTypeView';
import ColorCode from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/ColorCode/ColorCodeView';
import ConsultantOnProcedure from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/ConsultantOnProcedure/ConsultantOnProcedureView';
import County from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/County/CountyView';
import CurrencyDenomination from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/CurrencyDenomination/CurrencyDenominationView';
import Details from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/Details/DetailsView';
import FuneralDirector from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/FuneralDirector/FuneralDirectorView';
import Item from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/Item/ItemView';
import LiveryPerson from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/LiveryPerson/LiveryPersonView';
import MedicalEntity from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/MedicalEntity/MedicalEntityView';
import PhotoType from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/PhotoType/PhotoTypeView';
import ReasonDetail from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/ReasonDetail/ReasonDetailView';
import Relationship from './views/CMSAdmin/Menu/ReferenceDataAdminModule/ReferenceMasterTableUnique/Relationship/RelationshipView';

// Your Profile Routes

import YourCasesAndReportsView from './views/YourProfile/YourCasesAndReportsView';
import YourReleasesView from './views/YourProfile/YourProfileModules/Reports/YourReleasesView.jsx';
import YourCasesView from './views/YourProfile/YourProfileModules/YourCasesView';
import YourReportsView from './views/YourProfile/YourProfileModules/YourReportsView.jsx';
import YourSecurityQuestionView from './views/YourProfile/YourProfileModules/YourSecurityQuestionView';
import YourProfileView from './views/YourProfile/YourProfileView';

// Business Logic Routes
import ActivityView from './views/CMSAdmin/BusinessLogicModule/BusinessRule/Activity/ActivityView';
import AddEditActivity from './views/CMSAdmin/BusinessLogicModule/BusinessRule/Activity/AddEditActivity';
import LiveryServices from './views/Case/CaseViewModules/LiveryServiceModule/LiveryServiceView';
import LocationList from './views/Case/CaseViewModules/LocationListModule/LocationListView';
import AddEditTemplateView from './views/SysAdmin/SysAdminModules/NotificationTemplate/AddEditTemplateView';
import NotificationTemplateView from './views/SysAdmin/SysAdminModules/NotificationTemplate/NotificationTemplateView';
import RolesAdminForm from './views/SysAdmin/SysAdminModules/RolesAdmin/RolesAdminForm';

import { DashboardView } from './views/Dashboard/DashboardView';

// LMS Routes
import ELabPMCaseView from './views/LMS/LMSModules/eLabModules/ELabPMCaseView';
import LMSView from './views/LMS/LMSView';

// Dev Routes
import MapTestView from './views/MapTestView';
import SignalRTest from './views/SignalRTest';
import SupportAgentView from './views/Support/SupportAgentView';

export default function AppRoutes(props) {
  const HomePageMemo = useMemo(
    () => (
      <ProtectedRoute>
        <HomeView />
      </ProtectedRoute>
    ),
    []
  );

  return (
    <Box sx={props.sx}>
      <Routes>
        <Route path='/home' element={HomePageMemo} />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute requiredRoles={['DASHBOARD-ACCESS']} checkRolesWithServer={true}>
              <DashboardView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/yourcases'
          element={
            <ProtectedRoute>
              <YourCasesView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/caseview'
          element={
            <ProtectedRoute>
              <CaseView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/caseviewtesting'
          element={
            <ProtectedRoute>
              <CaseViewTesting />
            </ProtectedRoute>
          }
        />

        {/* <Route
            path='/tools'
            element={
              <ProtectedRoute>
                <ToolsView />
              </ProtectedRoute>
            }
          /> */}

        <Route
          path='/resources'
          element={
            <ProtectedRoute>
              <ResourcesView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/cmsadmin'
          element={
            <ProtectedRoute requiredRoles={['SYSTEMS-ADMIN']} checkRolesWithServer={true}>
              <CMSAdminView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/lmsadmin'
          element={
            <ProtectedRoute>
              <LMSAdminView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/lms'
          element={
            <ProtectedRoute requiredRoles={['MDXLMS-ACCESS']}>
              <LMSView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/ElabPMCaseView'
          element={
            <ProtectedRoute>
              <ELabPMCaseView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/securityadmin'
          element={
            <ProtectedRoute requiredRoles={['SECURITY-ADMIN']} checkRolesWithServer={true}>
              <SecurityAdminView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/support'
          element={
            <ProtectedRoute requiredRoles={['8f7']} checkRolesWithServer={true}>
              <SupportAgentView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/sysadmin'
          element={
            <ProtectedRoute requiredRoles={['SYSTEMS-ADMIN']} checkRolesWithServer={true}>
              <SysAdminHomeView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/sysadmin/server-dashboard'
          element={
            <ProtectedRoute requiredRoles={['SYSTEMS-ADMIN']} checkRolesWithServer={true}>
              <ServerMonitoringDashboard />
            </ProtectedRoute>
          }
        />

        <Route path='/securityquestion' element={<YourSecurityQuestionView />} />
        <Route path='/yourprofile' element={<YourProfileView />} />
        <Route path='/yourcasesandreports' element={<YourCasesAndReportsView />} />
        <Route path='/your-reports' element={<YourReportsView />} />
        <Route path='/reports/your-releases' element={<YourReleasesView />} />

        <Route path='/' element={<LoginView />} />
        <Route element={<LoginView />} path='/login'>
          <Route index element={<LoginForm />} />
          <Route path='2fa' element={<TwoFactorView />}>
            <Route index element={<TwoFactorMethodSelector />} />
            <Route path='email' element={<TwoFactorCodeForm method='email' />} />
            <Route path='sms' element={<TwoFactorCodeForm method='sms' />} />
          </Route>
          <Route path='forgot-password' element={<ForgotPasswordView />}>
            <Route index element={<ForgotPasswordForm />} />
            <Route path='verify' element={<ForgotPasswordCodeForm />} />
            <Route path='change' element={<NewPasswordForm />} />
          </Route>
        </Route>

        <Route
          path='/casesearch'
          element={
            <ProtectedRoute>
              <CaseSearchView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/dailyexamlist'
          element={
            <ProtectedRoute>
              <DailyExamListView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/quickcasesearch'
          element={
            <ProtectedRoute>
              <QuickCaseSearchView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/casespastduereport'
          element={
            <ProtectedRoute>
              <CasesPastDueView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/missingdemographicsreport'
          element={
            <ProtectedRoute>
              <MissingDemographicsView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/missingreasonforinvestigationreport'
          element={
            <ProtectedRoute>
              <MissingReasonforInvestigationView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/dataentryresponsetimereport'
          element={
            <ProtectedRoute>
              <DataEntryResponseTimeView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/caseintake'
          element={
            <ProtectedRoute
              requiredRoles={[
                'SYSTEMS-ADMIN',
                'HISTORIC-DATAENTRYONLY',
                'Investigator',
                'Medical Examiner',
                'CREATE-CASE-ACCESS',
              ]}
            >
              <CaseIntakeView />
            </ProtectedRoute>
          }
        />

        <Route
          path='/createcase'
          element={
            <ProtectedRoute>
              <__QuickCreatingCaseView requiredRoles={['SYSTEMS-ADMIN']} />
            </ProtectedRoute>
          }
        />

        <Route
          path='/add-user'
          element={
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          }
        />
        <Route
          path='/edit-user'
          element={
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          }
        />
        <Route
          path='/login-history'
          element={
            <ProtectedRoute>
              <LoginHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path='/notes'
          element={
            <ProtectedRoute>
              <Notes />
            </ProtectedRoute>
          }
        />

        <Route
          path='/documents'
          element={
            <ProtectedRoute>
              <Documents />
            </ProtectedRoute>
          }
        />
        <Route path='/sysadmin/reference-data/'>
          <Route
            path='type1/:referenceMasterTableName'
            element={
              <ProtectedRoute>
                <ReferenceMasterTableType1 />
              </ProtectedRoute>
            }
          />
          <Route
            path='type2/:referenceMasterTableName'
            element={
              <ProtectedRoute>
                <ReferenceMasterTableType2 />
              </ProtectedRoute>
            }
          />
          <Route path='unique/'>
            <Route
              path='PhotoType'
              element={
                <ProtectedRoute>
                  <PhotoType />
                </ProtectedRoute>
              }
            />
            <Route
              path='ReasonDetail'
              element={
                <ProtectedRoute>
                  <ReasonDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path='ColorCode'
              element={
                <ProtectedRoute>
                  <ColorCode />
                </ProtectedRoute>
              }
            />
            <Route
              path='FuneralDirector'
              element={
                <ProtectedRoute>
                  <FuneralDirector />
                </ProtectedRoute>
              }
            />
            <Route
              path='ConsultantOnProcedure'
              element={
                <ProtectedRoute>
                  <ConsultantOnProcedure />
                </ProtectedRoute>
              }
            />
            <Route
              path='AuthorityType'
              element={
                <ProtectedRoute>
                  <MedicalEntity />
                </ProtectedRoute>
              }
            />
            <Route
              path='LiveryPerson'
              element={
                <ProtectedRoute>
                  <LiveryPerson />
                </ProtectedRoute>
              }
            />
            <Route
              path='ClothingType'
              element={
                <ProtectedRoute>
                  <ClothingType />
                </ProtectedRoute>
              }
            />
            <Route
              path='County'
              element={
                <ProtectedRoute>
                  <County />
                </ProtectedRoute>
              }
            />
            <Route
              path='Denomination'
              element={
                <ProtectedRoute>
                  <CurrencyDenomination />
                </ProtectedRoute>
              }
            />
            <Route
              path='Item'
              element={
                <ProtectedRoute>
                  <Item />
                </ProtectedRoute>
              }
            />
            <Route
              path='Relship'
              element={
                <ProtectedRoute>
                  <Relationship />
                </ProtectedRoute>
              }
            />
            <Route
              path='CaseLocation'
              element={
                <ProtectedRoute>
                  <Details />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path='/sysadmin/businessrule'>
          <Route
            index
            element={
              <ProtectedRoute>
                <ActivityView />
              </ProtectedRoute>
            }
          />
          <Route
            element={
              <ProtectedRoute>
                <AddEditActivity />
              </ProtectedRoute>
            }
            path='add-activity'
          />
          <Route
            element={
              <ProtectedRoute>
                <AddEditActivity />
              </ProtectedRoute>
            }
            path='edit-activity'
          />
        </Route>
        <Route
          path='/administration/location-list'
          element={
            <ProtectedRoute>
              <LocationList />
            </ProtectedRoute>
          }
        />
        <Route
          path='/administration/livery-service'
          element={
            <ProtectedRoute>
              <LiveryServices />
            </ProtectedRoute>
          }
        />
        <Route
          path='sysadmin/rolesedit'
          element={
            <ProtectedRoute>
              <RolesAdminForm />
            </ProtectedRoute>
          }
        />
        <Route
          path='/sysadmin/notificationtemplate'
          element={
            <ProtectedRoute>
              <NotificationTemplateView />
            </ProtectedRoute>
          }
        />
        <Route
          path='/sysadmin/editnotification'
          element={
            <ProtectedRoute>
              <AddEditTemplateView />
            </ProtectedRoute>
          }
        />

        {/* Dev Routes */}
        <Route
          path='/signalrtest'
          element={
            <ProtectedRoute>
              <SignalRTest />
            </ProtectedRoute>
          }
        />

        <Route
          path='/maptest'
          element={
            <ProtectedRoute>
              <MapTestView />
            </ProtectedRoute>
          }
        />

        {/* Reports */}
        <Route
          path='reports/'
          element={
            <ProtectedRoute requiredRoles={['REPORTS-MENU-ACCESS']} checkRolesWithServer={true}>
              <ReportsView />
            </ProtectedRoute>
          }
        />
        <Route path='reports/'>
          {/* General Reports */}
          <Route
            path='turnaroundtimereport'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <CaseTurnAroundTimeView />
              </ProtectedRoute>
            }
          />
          <Route
            path='caseresponsetime'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <CaseResponseTimeView />
              </ProtectedRoute>
            }
          />
          <Route
            path='examssummaryreport'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <ExamsSummaryReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='meactionactivityreport'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <MEActionActivityReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='drugtoxicity'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <DrugToxicityView />
              </ProtectedRoute>
            }
          />
          <Route
            path='opioiddeath'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <OpioidDeathView />
              </ProtectedRoute>
            }
          />
          <Route
            path='suicide'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <SuicideView />
              </ProtectedRoute>
            }
          />
          <Route
            path='homicide'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <HomicideView />
              </ProtectedRoute>
            }
          />
          <Route
            path='violentdeath'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <ViolentDeathView />
              </ProtectedRoute>
            }
          />
          <Route
            path='unclaimedcases'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <UnclaimedCasesView />
              </ProtectedRoute>
            }
          />
          <Route
            path='monthlysuspecteddrugs'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <MonthlySuspectedDrugsView />
              </ProtectedRoute>
            }
          />
          <Route
            path='analyteDetected'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <AnalyteDetectedView />
              </ProtectedRoute>
            }
          />
          <Route
            path='casesnoNOK'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <CasesNoNOKReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='piicasesearch'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <PIICaseSearchView />
              </ProtectedRoute>
            }
          />
          <Route
            path='reasonforinvestigation'
            element={
              <ProtectedRoute
                requiredRoles={['GENERAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <ReasonForInvestigationView />
              </ProtectedRoute>
            }
          />

          {/* Admin Reports */}
          <Route
            path='missingcasedocuments'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <MissingCaseDocumentsView />
              </ProtectedRoute>
            }
          />
          <Route
            path='pathologistactivityreport'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <PathologistActivityReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='openpathologistcases'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <OpenPathologistCasesView />
              </ProtectedRoute>
            }
          />
          <Route
            path='investigatoractivityreport'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <InvestigatorCaseActivityReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='morguetechcaseactivity'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <MorgueTechCaseActivityView />
              </ProtectedRoute>
            }
          />
          <Route
            path='sceneexaminedby'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <SceneExaminedByView />
              </ProtectedRoute>
            }
          />
          <Route
            path='drugtoxaudit'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <DrugToxAuditView />
              </ProtectedRoute>
            }
          />
          <Route
            path='meturnaroundtimereport'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <METurnaroundTimeView />
              </ProtectedRoute>
            }
          />
          <Route
            path='casesbygeneralstaff'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <CasesCreatedByGeneralStaffView />
              </ProtectedRoute>
            }
          />
          <Route
            path='opencasesbyadmin'
            element={
              <ProtectedRoute
                requiredRoles={['SUPERVISOR-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <OpenAdminAssistantCasesView />
              </ProtectedRoute>
            }
          />
          {/* Business Reports */}
          <Route
            path='completedpathologistexams'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <CompletedPathologistExamsView />
              </ProtectedRoute>
            }
          />
          <Route
            path='deathinvestigationssummary'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <DeathInvestigationsSummaryView />
              </ProtectedRoute>
            }
          />
          <Route
            path='labtestingtagsreport'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <LabTestingTagsReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='casepaymentsreport'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <CasePaymentsReportView />
              </ProtectedRoute>
            }
          />
          <Route
            path='approvedreleasesbypathologist'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <ApprovedReleaseCasesByPathologistView />
              </ProtectedRoute>
            }
          />
          <Route
            path='closedcasesbypathologist'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <ClosedCasesByPathologistView />
              </ProtectedRoute>
            }
          />
          <Route
            path='examreportuploadedbypathologist'
            element={
              <ProtectedRoute
                requiredRoles={['FINANCIAL-REPORTS-ACCESS']}
                checkRolesWithServer={true}
              >
                <ExamReportUploadedByPathologistReportView />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Box>
  );
}
