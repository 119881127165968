import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useAuth } from '../utils/auth/AuthService';

const { REACT_APP_API_URL } = process.env;

interface AppSettings {
    key: string;
    value?: string | null;
}

interface AppSettingsContextType {
  appSettings: AppSettings[] | null;
  loading: boolean;
  error: string | null;
  getAppSettingByKey: (key: string) => string | null;
}

const AppSettingsContext = createContext<AppSettingsContextType | undefined>(undefined);

export const AppSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [appSettings, setAppSettings] = useState<AppSettings[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const connectionRef = useRef<HubConnection | null>(null);


  const getAppSettingByKey = (key: string) => {
    const setting = appSettings?.find((setting) => setting.key === key);
    return setting?.value ?? null;
  };

  const fetchGlobalAppSettings = async () => {
    try {
      if (user === null) return;

      if (appSettings && appSettings?.length > 0) {
        return; 
      }

      setLoading(true);

      const response = await fetch(
        `${REACT_APP_API_URL}GlobalAppSettings/getallglobalappsettings`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + user.token,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch settings from database');
      }
      
      const fetchedSettings: AppSettings[] = await response.json();

      setAppSettings(fetchedSettings.map(({ key, value }) => ({ key, value })));
    } catch (err) {
      console.error('Error fetching settings:', err);
      setError('Failed to fetch settings from database');
    } finally {
        setLoading(false);
      }
  };

  useEffect(() => {
    fetchGlobalAppSettings();
  }, []);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(REACT_APP_API_URL + 'globalAppSettingsHub')
      .withAutomaticReconnect()
      .build();

    connectionRef.current = connection;

    connection
      .start()
      .then(() => {
        console.log('SignalR connection established');
      })
      .catch((err) => {
        console.error('SignalR connection error:', err);
        setError('Error connecting to SignalR Hub');
      });

    connection.on('GlobalAppSettingsUpdated', (updatedAppSettings: AppSettings[]) => {
      setAppSettings(updatedAppSettings.map(({ key, value }) => ({ key, value })));
    });

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop().catch((err) => console.error('Error stopping SignalR connection:', err));
      }
    };
  }, []);

  const value = {
    appSettings,
    loading,
    error,
    getAppSettingByKey,
  };

  return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>;
};

export const useAppSettings = (): AppSettingsContextType => {
  const context = useContext(AppSettingsContext);
  if (!context) {
    throw new Error('useAppSettings must be used within a AppSettingsProvider');
  }
  return context;
};