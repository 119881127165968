import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useAuth } from '../utils/auth/AuthService';

import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import SiloDataGrid from '../utils/components/SiloDataGrid';
import { fetchGlobalAppSetting } from '../utils/functions/fetchGlobalAppSetting';

export default function QuickCaseSearchView(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quickSearchMaxYears, setQuickSearchMaxYears] = useState(75);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { REACT_APP_API_URL } = process.env;

  // Extract the lastname prop

  const location = useLocation();
  const { lastname } = location.state || {};

  const handleCaseClick = (event, data) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  const jdxListSelection = user.jdxAccessList.map(jurisdiction => jurisdiction.jdxSeq);

  const fetchSetting = async () => {
    try {
      const res = await fetchGlobalAppSetting({
        settingName: 'QuickSearchMaxYears',
        accessToken: user.token,
      });
      setQuickSearchMaxYears(parseInt(res?.value));
    } catch (error) {
      console.error('Error fetching setting:', error);
      return null;
    }
  };

  useEffect(() => {
    fetchSetting(); // only on mount
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestBody = {
          JdxList: user.jdxAccessList.map(jurisdiction => jurisdiction.jdxSeq),
          LastName: lastname, //
          NumberOfYears: quickSearchMaxYears,
        };

        const response = await fetch(`${REACT_APP_API_URL}quickcasesearch`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const responseData = await response.json();
          setData(responseData); // Set the data state with API response
        } else {
          // Handle HTTP errors
          console.error('HTTP error:', response.status);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, lastname]);

  const columns = [
    {
      field: 'caseid',
      headerName: 'Case ID',
      width: 105,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <Button
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            <b> {params.formattedValue} </b>
          </Button>
        );
      },
    },
    // { field: 'caseSeq', headerName: 'CaseSeq', width: 150 },
    // { field: 'caseid', headerName: 'Case ID', width: 150 },
    {
      field: 'combinedName',
      headerName: 'Decedent Name',
      width: 200,
      renderCell: params => {
        // Assuming params.row contains the data for the current row
        const lastName = params.row.lastName || '';
        const firstName = params.row.firstName || '';
        return <span>{`${lastName}, ${firstName}`}</span>;
      },
    },
    {
      field: 'pronouncementDateTime',
      headerName: 'Date Pronounced',
      width: 150,
      renderCell: params => {
        // Check if the value is a valid date
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          // If it's not a valid date, return a default value or an indication of invalid date
          return <span></span>;
        } else {
          // If it is a valid date, format and return it
          const formattedDate = date.toISOString().split('T')[0];
          return <span>{formattedDate}</span>;
        }
      },
    },
    // { field: 'lastName', headerName: 'Last Name', width: 150 },
    // { field: 'firstName', headerName: 'First Name', width: 150 },
    // { field: 'dateOfBirth', headerName: 'DOB', width: 150 },
    { field: 'jurisdictionName', headerName: 'Jurisdiction', width: 150 },
    {
      field: 'dateOfBirth',
      headerName: 'BirthDate',
      width: 150,
      renderCell: params => {
        // Check if the value is a valid date
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          // If it's not a valid date, return a default value or an indication of invalid date
          return <span></span>;
        } else {
          // If it is a valid date, format and return it
          const formattedDate = date.toISOString().split('T')[0];
          return <span>{formattedDate}</span>;
        }
      },
    },

    // { field: 'middleName', headerName: 'Middle Name', width: 150 },

    {
      field: 'meNotifiedDateTime',
      headerName: 'Date Reported',
      width: 150,
      renderCell: params => {
        // Check if the value is a valid date
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          // If it's not a valid date, return a default value or an indication of invalid date
          return <span></span>;
        } else {
          // If it is a valid date, format and return it
          const formattedDate = date.toISOString().split('T')[0];
          return <span>{formattedDate}</span>;
        }
      },
    },
    // Add other columns as needed
  ];

  return (
    <Box sx={{ height: 600, width: 'calc(100% - 32px)', ml: 2, mr: 2 }}>
      {/* {console.log( data) } */}
      <Stack mt={2}>
        <Typography variant='h5' component='h1'>
          <b>Quick Search</b>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          Last Name: <strong>{lastname}</strong>
        </Typography>
        <Typography variant='subtitle1' color='gray'>
          Records Found:{' '}
          {loading ? (
            <CircularProgress size={20} /> // Spinner shown while loading
          ) : (
            <strong>{data.length}</strong> // Show record count when not loading
          )}{' '}
          (past {quickSearchMaxYears} years)
        </Typography>
      </Stack>

      <Divider sx={{ borderColor: '#555', m: 0, mt: 3, pb: 0 }} />
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <SiloDataGrid
          loading={loading}
          listresults={data}
          columnsInput={columns}
          pageSize={10}
          idcolumn='caseid'
          gridcolor='#DB9E09'
          title=' '
          tooltip='Quick search by Name'
          // casecount
          rowsperpage={50}
        />
      )}
      {/* <DataGridPremium
                rows={data}
                columns={columns}
                gridheight={600}
                pageSize={10}
                loading={loading}
                getRowId={(row) => row.caseSeq}
                // hideFooterPagination={true}
            /> */}
    </Box>
  );
}
