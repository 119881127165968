import { PostAdd, Refresh } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { differenceInDays, format, formatDistanceToNow, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import {
  ConversationMessage,
  ConversationTypes,
  shouldMaskNameAsSupportAgent,
} from './conversation.types';
import { useConversation } from './ConversationsContext';
import { ConversationTypeFilter } from './ConversationTypeFilter';
import { NewConversation } from './Dialogs/NewConversationDialog';
import { StartFeedbackConversationDialog } from './Dialogs/StartFeedbackDialog';

const formatMessageTime = (dateStr: string) => {
  const date = parseISO(dateStr);
  const daysDiff = differenceInDays(new Date(), date);

  // Add check for messages within the last minute
  const diffInSeconds = (new Date().getTime() - date.getTime()) / 1000;
  if (diffInSeconds < 60) {
    return 'now';
  }

  if (daysDiff >= 7) {
    return format(date, 'dd/MM/yyyy hh:mm a');
  }

  const formatted = formatDistanceToNow(date, { addSuffix: true })
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace('about ', '')
    .replace('less than ', '<')
    .replace('almost ', '~');

  return formatted;
};

export function ConversationList({
  sortedConversations = [],
  title = 'User Feedback',
  kind = 'user',
}) {
  const { user } = useAuth();
  const {
    updateConversationOpenStatus,
    handleSelectConversation,
    selectedConversation,
    refreshing,
    refreshConversations,
    refreshUserConversations,
    typingParticipants,
    supportAgentName,
    conversationTypes,
  } = useConversation();
  const userIsSysAdmin = user.roleCheck(['SYSTEMS-ADMIN', '8f7']) || false;

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [openFilter, setOpenFilter] = useState(userIsSysAdmin ? 'open' : 'all');

  const hasUnreadMessages = conversation => {
    return conversation.messages.some(
      message =>
        !message.readBy.some(reader => reader.userSeq.toLowerCase() === user.userSeq.toLowerCase())
    );
  };

  const handleTypeToggle = typeSeq => {
    setSelectedTypes(prev =>
      prev.includes(typeSeq) ? prev.filter(t => t !== typeSeq) : [...prev, typeSeq]
    );
  };

  const filteredConversations = useMemo(() => {
    let filtered = sortedConversations;
    if (selectedTypes.length > 0) {
      filtered = filtered.filter(conversation =>
        selectedTypes.includes(conversation.conversationType.conversationTypeSeq)
      );
    }
    if (openFilter !== 'all') {
      filtered = filtered.filter(conversation =>
        openFilter === 'open' ? conversation.isOpen : !conversation.isOpen
      );
    }
    return filtered;
  }, [sortedConversations, selectedTypes, openFilter]);

  const getSecondaryText = conversation => {
    const activeTypers = typingParticipants[conversation.conversationSeq] || [];

    if (activeTypers.length > 0) {
      const typerNames = activeTypers
        .map(participant => {
          if (
            shouldMaskNameAsSupportAgent(
              { senderUserSeq: participant.userSeq } as ConversationMessage,
              conversation.conversationType.conversationTypeSeq,
              user
            )
          ) {
            return supportAgentName;
          }
          return participant.personFirstName
            ? `${participant.personFirstName} ${participant.personLastName}`
            : participant.userName;
        })
        .filter(Boolean)
        .join(', ');

      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            <span style={{ fontWeight: 600 }}>{typerNames} </span>
            is typing...
          </Typography>
          <Typography variant='caption' color='text.secondary' sx={{ ml: 1, flexShrink: 0 }}>
            {' '}
          </Typography>
        </Box>
      );
    }

    if (conversation.messages.length === 0) {
      return (
        <Typography variant='body2' color='text.secondary'>
          No messages yet
        </Typography>
      );
    }

    const lastMessage = conversation.messages[conversation.messages.length - 1];
    const senderName = shouldMaskNameAsSupportAgent(
      lastMessage,
      conversation.conversationType.conversationTypeSeq,
      user
    )
      ? supportAgentName
      : lastMessage.senderName;

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          <span style={{ fontWeight: 600 }}>{senderName}: </span>
          {lastMessage.content.substring(0, 30)}...
        </Typography>
        <Typography variant='caption' color='text.secondary' sx={{ ml: 1, flexShrink: 0 }}>
          {formatMessageTime(lastMessage.sentAt)}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <List
        sx={{ p: 0, backgroundColor: user.isDarkMode ? 'black' : 'white' }}
        subheader={
          <ListSubheader
            sx={{ pt: 6, pb: 4, backgroundColor: user.isDarkMode ? 'black' : 'white' }}
          >
            <Stack direction='row' justifyContent='space-between'>
              <Stack alignContent='flex-start' alignItems='flex-start' spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    variant='h6'
                    fontWeight='bold'
                    color={user.isDarkMode ? 'white' : 'black'}
                  >
                    {title} — {filteredConversations.length}
                  </Typography>
                  <IconButton
                    onClick={
                      user.roleCheck(['SYSTEMS-ADMIN', '8f7'])
                        ? refreshConversations
                        : () =>
                            refreshUserConversations({
                              userSeq: user.userSeq,
                              conversationTypeSeqs: [
                                ConversationTypes.Chat,
                                ConversationTypes.Feedback,
                                ConversationTypes.New_Account_Request,
                              ],
                            })
                    }
                    size='small'
                    disabled={refreshing}
                    sx={{
                      ml: 1,
                      animation: refreshing ? 'spin 1s linear infinite' : 'none',
                      '@keyframes spin': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' },
                      },
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Box>

                {userIsSysAdmin && (
                  <ConversationTypeFilter
                    types={conversationTypes}
                    selectedTypes={selectedTypes}
                    onTypeToggle={handleTypeToggle}
                  />
                )}

                {userIsSysAdmin && (
                  <ButtonGroup variant='contained' size='small'>
                    <Button
                      onClick={() => setOpenFilter('open')}
                      variant={openFilter === 'open' ? 'contained' : 'outlined'}
                    >
                      Open
                    </Button>
                    <Button
                      onClick={() => setOpenFilter('closed')}
                      variant={openFilter === 'closed' ? 'contained' : 'outlined'}
                    >
                      Closed
                    </Button>
                    <Button
                      onClick={() => setOpenFilter('all')}
                      variant={openFilter === 'all' ? 'contained' : 'outlined'}
                    >
                      All
                    </Button>
                  </ButtonGroup>
                )}
              </Stack>

              <Button
                variant='text'
                size='small'
                startIcon={<PostAdd />}
                onClick={() => setCreateDialogOpen(true)}
              >
                New Chat
              </Button>

              {/* <Button
                variant='text'
                size='small'
                startIcon={<PostAdd />}
                onClick={() => setFeedbackDialogOpen(true)}
              >
                New Feedback
              </Button> */}
            </Stack>
          </ListSubheader>
        }
      >
        {filteredConversations.length === 0 ? (
          <ListItem sx={{ borderBottom: '1px solid', borderColor: 'divider', bgcolor: 'inherit' }}>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    variant='body1'
                    sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                  >
                    {openFilter === 'all'
                      ? "You've submitted no feedback yet.\nWhen you do, it will appear here."
                      : `No ${openFilter} conversations found.`}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ) : (
          filteredConversations.map(conversation => (
            <ListItem
              key={conversation?.conversationSeq}
              disablePadding
              sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                bgcolor:
                  selectedConversation?.conversationSeq === conversation?.conversationSeq
                    ? 'action.selected'
                    : 'inherit',
              }}
            >
              <ListItemButton onClick={() => handleSelectConversation(conversation)}>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{
                          fontWeight: hasUnreadMessages(conversation) ? 700 : 400,
                          wordBreak: 'break-word',
                          whiteSpace: 'pre-wrap',
                          flex: 1,
                          mr: 2,
                        }}
                      >
                        {conversation.topic}
                      </Typography>
                      {userIsSysAdmin && (
                        <Box sx={{ display: 'flex', gap: 1, flexShrink: 0 }}>
                          <Chip
                            label={conversation.conversationType.typeName}
                            size='small'
                            variant='outlined'
                          />
                          <Chip
                            label={conversation.isOpen ? 'Open' : 'Closed'}
                            size='small'
                            color={conversation.isOpen ? 'success' : 'default'}
                            onClick={() =>
                              updateConversationOpenStatus({
                                conversationSeq: conversation.conversationSeq,
                                isOpen: !conversation.isOpen,
                              })
                            }
                            sx={{ cursor: 'pointer' }}
                          />
                        </Box>
                      )}
                    </Box>
                  }
                  secondary={getSecondaryText(conversation)}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>

      <NewConversation
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        isDialog={true}
      />
      <StartFeedbackConversationDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        onConversationCreated={c => {}}
        isDialog={true}
      />
    </>
  );
}
