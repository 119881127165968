import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import CondensedDataGrid from '../components/CondensedDataGrid';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { useAuth } from '../utils/auth/AuthService';

interface UnconfirmIDProps {
  identificationscolumns: GridColDef[];
  fetchIdentifications?: () => Promise<void>;
}

const UnconfirmID: React.FC<UnconfirmIDProps> = ({
  identificationscolumns,
  fetchIdentifications,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const theme = useTheme();
  const formik = useFormikContext<any>();
  const { REACT_APP_API_URL } = process.env;
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const [loading, setLoading] = useState(false);

  const confirmedIdentifications =
    formik.values?.caseDemographics?.identifications?.filter(
      identification => identification.isAccept === true
    ) || [];

  const handleUnconfirmDialogClick = async () => {
    let formData = new FormData();
    formData.append('identificationDetailInfoSeq', ''); //it was not used, we ca use it if want to allow unique unconfirm identification.
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq ?? null);
    formData.append('userSeq', user?.userSeq);
  
    if (user && user.token) {
      setLoading(true);
      fetch(REACT_APP_API_URL + 'unconfirmIdentification', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        body: formData,
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          // fetchIdentifications();
        })
        .catch(e => {
          alert(e);
        })
        .finally(async () => {
          if (fetchIdentifications) {
            await fetchIdentifications();
          }
          setLoading(false);
          setIsDialogOpen(false);
        });
    } else {
      console.log('user or token is null');
      setIsDialogOpen(false);
    }
   
  };

  const handleUnconfirmClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <FormControlLabel
        style={{ color: 'red', display: 'inline-flex', marginLeft: '1rem' }}
        control={<span />}
        label={
          <span style={{ fontSize: '0.75rem' }} onClick={handleUnconfirmClick}>
            Unconfirm ID
          </span>
        }
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            minWidth: isMobile ? '95%' : isLaptop ? '80%' : '70%',
            margin: '0 auto',
          },
        }}
      >
        <DialogTitle>
          <span>Confirmed ID Details</span>
        </DialogTitle>

        <DialogContent>
          <CondensedDataGrid
            listresults={confirmedIdentifications}
            columnsInput={identificationscolumns}
            idcolumn='identificationDetailSeq'
            gridcolor='#0288d1'
            gridheight={confirmedIdentifications?.length > 0 ? 'auto' : '100'}
            rowsperpage={5}
            tooltip='Identification List'
            columnsVisibility={{
              actions: false,
            }}
          />

          <DialogActions style={{ justifyContent: 'left', marginTop: '1rem' }}>
            <Button
              type='submit'
              variant='contained'
              onClick={handleUnconfirmDialogClick}
              disabled={loading} // Disable button while loading
              startIcon={loading && <CircularProgress size={20} color='inherit' />}
            >
              Unconfirm
            </Button>
            <Button color='error' onClick={handleCloseDialog}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UnconfirmID;
