import {
  Autocomplete,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useAuth } from '../utils/auth/AuthService';

export default function JurisdictionAutocomplete({
  selectedJurisdictions,
  setSelectedJurisdictions,
}) {
  const { user } = useAuth();
  const theme = useTheme();

  const handleSelectedJurisdictionsChange = (_, value) => {
    setSelectedJurisdictions(value);
  };

  const handleSelectAndDeselectAllJurisdictions = () => {
    if (JSON.stringify(user?.jdxAccessList) === JSON.stringify(selectedJurisdictions)) {
      setSelectedJurisdictions([]);
    } else {
      setSelectedJurisdictions(user?.jdxAccessList);
    }
  };

  return (
    <Autocomplete
      multiple
      options={user?.jdxAccessList?.sort((a, b) => a.jdxName.localeCompare(b.jdxName)) ?? []}
      value={selectedJurisdictions}
      disableCloseOnSelect
      size='small'
      getOptionLabel={option => option.jdxName}
      onChange={handleSelectedJurisdictionsChange}
      renderOption={(props, option, { selected }) => {
        if (
          option.jdxName ==
          user?.jdxAccessList?.sort((a, b) => a.jdxName.localeCompare(b.jdxName))[0].jdxName
        ) {
          return (
            <Stack>
              <Button
                sx={{
                  backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#cccccc',
                  color: theme.palette.text.primary,
                  p: 1,
                  m: 0.5,
                  boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)',
                }}
                onClick={handleSelectAndDeselectAllJurisdictions}
              >
                {JSON.stringify(selectedJurisdictions) == JSON.stringify(user?.jdxAccessList) ? (
                  <Typography variant='button'>Deselect All</Typography>
                ) : (
                  <Typography variant='button'>Select All</Typography>
                )}
              </Button>
              <li {...props}>
                <Checkbox
                  icon={<MdCheckBoxOutlineBlank />}
                  checkedIcon={<MdOutlineCheckBox />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.jdxName}
              </li>
            </Stack>
          );
        }
        return (
          <li {...props}>
            <Checkbox
              icon={<MdCheckBoxOutlineBlank />}
              checkedIcon={<MdOutlineCheckBox />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.jdxName}
          </li>
        );
      }}
      fullWidth
      renderInput={params => (
        <TextField {...params} label='Jurisdictions' placeholder='Jurisdictions' />
      )}
    />
  );
}
