import { Add, Delete, Edit, Info } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import dayjs, { isDayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CustomPagination from './CustomPagination';
import LoadingIconButton from './LoadingIconButton';

interface DialogCrudDataGridProps extends Partial<DataGridPremiumProps> {
  title?: string;
  columns: GridColDef[];
  rows: any[];
  createFunction?: (data: any) => Promise<void>;
  updateFunction?: (data: any) => Promise<void>;
  deleteFunction?: (id: any) => Promise<void>;
  dialogContent?: (props: {
    mode: 'add' | 'edit';
    data: any;
    onChange: (data: any) => void;
  }) => React.ReactNode;
  idColumn?: string;
  legend?: React.ReactNode | null;
}

export const getDateValue = date => {
  if (date == null || date === '' || (isDayjs(date) && !date.isValid())) return null;
  else return dayjs(date);
};

export const handleAutocompleteChange = (
  _,
  value,
  fieldName,
  localData,
  setLocalData,
  onChange
) => {
  const newData = { ...localData, [fieldName]: value };
  setLocalData(newData);
  onChange(newData);
};

export const handleDateChange = (value, fieldName, localData, setLocalData, onChange) => {
  const newData = {
    ...localData,
    [fieldName]: getDateValue(value)?.format('YYYY-MM-DDTHH:mm:ss') ?? '',
  };
  setLocalData(newData);
  onChange(newData);
};

export const handleTextFieldChange = (event, fieldName, localData, setLocalData, onChange) => {
  const newData = { ...localData, [fieldName]: event.target.value };
  setLocalData(newData);
  onChange(newData);
};

export const handleCheckboxChange = (event, fieldName, localData, setLocalData, onChange) => {
  const newData = { ...localData, [fieldName]: event.target.checked };
  setLocalData(newData);
  onChange(newData);
};

export const handleNumericTextFieldChange = (
  value,
  fieldName,
  localData,
  setLocalData,
  onChange
) => {
  const newData = { ...localData, [fieldName]: value };
  setLocalData(newData);
  onChange(newData);
};

const DialogCrudDataGrid: React.FC<DialogCrudDataGridProps> = ({
  title = undefined,
  columns,
  rows,
  createFunction,
  updateFunction,
  deleteFunction,
  dialogContent,
  idColumn = 'id',
  legend = null,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
  const [currentData, setCurrentData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);

  useEffect(() => {
    setFormData(currentData);
  }, [currentData]);

  const handleAdd = () => {
    if (createFunction && dialogContent) {
      setDialogMode('add');
      const initialData = { [idColumn]: uuidv4() };
      setCurrentData(initialData);
      setFormData(initialData);
      setOpen(true);
    }
  };

  const handleEdit = (rowData: any, event: React.MouseEvent) => {
    event.stopPropagation();
    if (updateFunction && dialogContent) {
      setDialogMode('edit');
      setCurrentData(rowData);
      setFormData(rowData);
      setOpen(true);
    }
  };

  const handleDelete = (id: any, event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleteId(id);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteFromDialog = () => {
    if (currentData) {
      setDeleteId(currentData[idColumn]);
      setConfirmDeleteOpen(true);
    }
  };

  const confirmDelete = async () => {
    setLoading(true);
    setConfirmDeleteOpen(false);
    try {
      if (deleteFunction) {
        await deleteFunction(deleteId);
        if (dialogMode === 'edit' && open) {
          setOpen(false);
          setFormData({});
          setCurrentData({});
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteId(null);
    }
  };

  const cancelDelete = () => {
    setConfirmDeleteOpen(false);
    setDeleteId(null);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setOpen(false);
    try {
      if (dialogMode === 'add' && createFunction) {
        await createFunction(formData);
      } else if (dialogMode === 'edit' && updateFunction) {
        await updateFunction(formData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setFormData({});
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setFormData({});
  };

  const handleFormDataChange = (data: any) => {
    setFormData(prevData => ({ ...prevData, ...data }));
  };

  const handleRowClick = (params: any) => {
    if (updateFunction && dialogContent) {
      setDialogMode('edit');
      setCurrentData(params.row);
      setFormData(params.row);
      setOpen(true);
    }
  };

  const actionColumn: GridColDef = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any, any>) => (
      <Stack direction='row' spacing={2}>
        {updateFunction && (
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            color='primary'
            className='textPrimary'
            onClick={event => handleEdit(params.row, event)}
            disabled={loading}
          />
        )}
        {deleteFunction && (
          <GridActionsCellItem
            icon={<Delete />}
            label='Delete'
            onClick={event => handleDelete(params.id, event)}
            color='error'
            disabled={loading}
          />
        )}
      </Stack>
    ),
  };

  const columnsWithActions =
    updateFunction || deleteFunction ? [...columns, actionColumn] : columns;

  const CustomToolbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openLegend = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleOpenLegend = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <GridToolbarContainer sx={{ p: 1 }}>
        {createFunction && (
          <Button color='primary' startIcon={<Add />} onClick={handleAdd}>
            Add New {title ?? 'Entry'}
          </Button>
        )}
        {legend && (
          <React.Fragment>
            <Button
              sx={{ marginLeft: 'auto' }}
              color='secondary'
              startIcon={<Info />}
              onClick={handleOpenLegend}
            >
              Tox Coding
            </Button>

            <Popover
              id={id}
              open={openLegend}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {legend}
            </Popover>
          </React.Fragment>
        )}
      </GridToolbarContainer>
    );
  };

  const slots = {
    ...other.slots,
    toolbar: createFunction ? CustomToolbar : other.slots?.toolbar,
    pagination: CustomPagination,
    noRowsOverlay: () => <></>,
  };

  return (
    <>
      <DataGridPremium
        autoHeight // deprecated but still useful until they add in another overlay autoHeight function
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: true,
          outliersFactor: 1.5,
          expand: true,
        }}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={handleRowClick}
        columns={columnsWithActions}
        rows={rows}
        slots={slots}
        loading={loading || other.loading}
        slotProps={{
          toolbar: {
            setRowModesModel,
            rowModesModel,
            rows,
            title,
          },
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        disableColumnMenu
        hideFooter={rows.length <= paginationModel.pageSize}
        rowHeight={30}
        columnHeaderHeight={28}
        getRowId={row => row?.[idColumn]}
        {...other}
      />
      {dialogContent && (
        <Dialog
          open={open}
          onClose={handleCancel}
          sx={{ '& .MuiPaper-root': { maxWidth: '100%', width: '80%' } }}
        >
          <DialogTitle>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              {dialogMode === 'add' ? `Add New ${title ?? 'Entry'}` : `Edit ${title ?? 'Entry'}`}
              {dialogMode === 'edit' && (
                <Tooltip title={`Delete ${title}`}>
                  <span>
                    <LoadingIconButton
                      loading={loading}
                      onClick={handleDeleteFromDialog}
                      color='error'
                      disabled={loading}
                    >
                      <Delete />
                    </LoadingIconButton>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </DialogTitle>
          <DialogContent>
            {dialogContent({
              mode: dialogMode,
              data: formData,
              onChange: handleFormDataChange,
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color='error' disabled={loading}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} color='primary' variant='contained' disabled={loading}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={confirmDeleteOpen} onClose={cancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this {title ?? 'entry'}?</DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color='primary' disabled={loading}>
            Cancel
          </Button>
          <Button onClick={confirmDelete} color='error' variant='contained' disabled={loading}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCrudDataGrid;
