import {
  Autocomplete,
  Checkbox,
  Chip,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import CaseViewTextField from '../CaseViewTextField';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';

export default function PathologyOptionsAutocomplete({ title, fieldName, apiEndpoint }) {
  const formik = useFormikContext<CMSCase>();
  const auth = useAuth();
  const [options, setOptions] = useState([]);
  const [showOtherTextField, setShowOtherTextField] = useState(false);
  const [otherIndex, setOtherIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    setOtherIndex(
      formik.values?.casePathology?.[fieldName]?.findIndex(
        (option: PathologyOption) => option.optionName === 'Other'
      ) ?? 0
    );
  }, [formik.values?.casePathology?.[fieldName]]);

  const fetchOptions = async () => {
    await fetch(`${REACT_APP_API_URL}${apiEndpoint}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${auth.user?.accessToken}` },
    })
      .then(res => res.json())
      .then(data => setOptions(data))
      .catch(e => console.error(e));
  };

  const getChipLabel = (option: PathologyOption) => {
    let label = option?.categoryName ? `${option.categoryName} - ` : '';
    return label + (option?.optionName ?? '');
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    const hasOther = formik.values?.casePathology?.[fieldName]?.some(
      (option: PathologyOption) => option.optionName === 'Other'
    );
    setShowOtherTextField(hasOther);
  }, [formik.values?.casePathology?.[fieldName]]);

  return (
    <Stack spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
      <Autocomplete
        multiple
        options={options ?? []}
        groupBy={option => option.categoryName}
        getOptionLabel={option => option.optionName}
        isOptionEqualToValue={(option, value) =>
          option?.optionSeq?.toUpperCase() === value?.optionSeq?.toUpperCase()
        }
        fullWidth
        size='small'
        renderInput={params => <TextField {...params} label={title} />}
        disabled={!formik.status.editing}
        disableCloseOnSelect
        id={`casePathology.${fieldName}`}
        value={formik.values?.casePathology?.[fieldName] ?? []}
        onChange={(_, value) => {
          formik.setFieldValue(`casePathology.${fieldName}`, value);
          const hasOther = value.some((option: PathologyOption) => option.optionName === 'Other');
          setShowOtherTextField(hasOther);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip size='small' label={getChipLabel(option)} {...getTagProps({ index })} />
          ))
        }
        renderOption={(props, option: Option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<MdCheckBoxOutlineBlank />}
              checkedIcon={<MdOutlineCheckBox />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.optionName}
          </li>
        )}
      />
      {showOtherTextField && (
        <CaseViewTextField
          xs={12}
          label={`Other ${title}`}
          fieldName={`casePathology.${fieldName}[${otherIndex}].otherText`}
        />
      )}
    </Stack>
  );
}
